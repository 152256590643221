'use strict';

var formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    util = require('../../util'),
    // ECM-880 - Adyen 3DSv2 - new logic
    adyenCheckout = require('./adyen/adyen-checkout'),
    googleplaces = require('../../googleplaces'),
    usaPostalMap = require('../../helpers/usaStatePostalMapping');

var $form, $loader, $continueButton, $ajaxLoading, $phone,
    ajaxRequestCount = 0,
    preventSubmitClick = false;

// Show wechat payment method only if the width is >= 1024 px
var $wechatPayment = $('[data-payment-id="ADYEN_WECHAT"]'),
    $wechatWrapper = $('.wechat-wrapper');

if ($wechatPayment.length && $wechatWrapper.length) {
    if (window.innerWidth >= 1024) {
        $wechatPayment.removeClass('hidden');
        $wechatWrapper.removeClass('hidden');
    }
    else {
        $wechatPayment.addClass('hidden');
        $wechatWrapper.addClass('hidden');
    }
}

/**
 * @function
 * @description function to show the page ajax loader and disable the submit button (does change the validation process submit button disable status)
 */
function showLoader() {
    ajaxRequestCount++;
    if (ajaxRequestCount === 1) {
        $loader.parent().addClass('gl_loading');
        $loader.removeClass('hidden');
        $ajaxLoading.val('').trigger('change'); // empty value = form invalid (see formPrepare.js)
    }
}

/**
 * @function
 * @description function to hide the page ajax loader and enable the submit button (does change the validation process submit button disable status)
 */
function hideLoader() {
    ajaxRequestCount--;
    if (ajaxRequestCount <= 0) {
        $loader.addClass('hidden');
        $loader.parent().removeClass('gl_loading');
        $ajaxLoading.val('done').trigger('change');
        ajaxRequestCount = 0; //security
    }
}

/**
 * ECM24-8 - Datalayer update (GA4 GTM)
 * Sends a Google Tag Manager event with the selected payment method
 * @param {string} paymentMethod - the selected payment method ( optional )
 * @returns {void}
 */
function sendGtmPaymentInfoEvent(paymentMethod) {
    var selectedPaymentMethod = $form.find('.input-radio').filter('[name$="_selectedPaymentMethodID"]').filter(':checked').val();
    var paymentOptions = {
        'CREDIT_CARD': 'Adyen Credit Card',
        'ADYEN_GIROPAY': 'Adyen Giropay',
        'ADYEN_SOFORT': 'Adyen SoFort',
        'ADYEN_IDEAL': 'Adyen iDeal',
        'ADYEN_EPS': 'Adyen EPS',
        'ADYEN_BANCONTACT': 'Adyen Bancontact',
        'ADYEN_ALIPAY': 'Adyen Alipay',
        'ADYEN_WECHAT': 'Adyen WeChat',
        // EKPM-1 - Add Klarna (Pay ¾ installment) as a mean of payment
        'ADYEN_KLARNA': 'Adyen Klarna'
    };

    var paymentType = paymentOptions[selectedPaymentMethod] || paymentMethod || '';

    window.vbqUtils.gtmUtils.pushCheckoutInfoEvent(2, {paymentType: paymentType});
}

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
/*
function setCCFields(data) {
    var $adyenForm = $('.payment-method').filter('[data-method="CREDIT_CARD"]');
    if ($adyenForm.length > 0) {
        $adyenForm.find('input[name*="_creditCard_owner"]').val(data.holder).trigger('change');
        $adyenForm.find('select[name*="_creditCard_type"]').val(data.type).trigger('change');
        $adyenForm.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
        $adyenForm.find('input[name*="_creditCard_expiration_month"]').val(data.expirationMonth).trigger('change');
        $adyenForm.find('input[name*="_creditCard_expiration_year"]').val(data.expirationYear).trigger('change');
        $adyenForm.find('input[name*="_creditCard_cvn"]').val('').trigger('change');

        // ECM-880 - Adyen 3DSv2 - new logic but not wanted
        $creditCard.find('[name$="creditCard_selectedCardID"]').val(data.selectedCardID).trigger('change');
    }
}
*/

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
/*
function populateCreditCardForm(cardID) {
    // load card details
    showLoader();
    $.getJson(
        util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID)
    ).done(function (data) {
        if (!data) {
            window.alert(Resources.CC_LOAD_ERROR);
            hideLoader();
            return false;
        }
        setCCFields(data);
        hideLoader();
    }).fail(function () {
        window.alert(Resources.CHECKOUT_AJAX_ERROR);
        hideLoader();
    });
}
*/

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    // ERB-116 Paypal payment provider
    // added removing and restoring credit card forms depending on selected payment method
    var $adyenForm = $('.cc-form'),
        $giropayForm = $('.giropay-form'),
        $sofortForm = $('.sofort-form'),
        $idealForm = $('.ideal-form'),
        $bancontactForm = $('.bancontact-form'),
        $epsForm = $('.eps-form'),
        $wechatForm = $('.wechat-form'),
        $alipayForm = $('.alipay-form'),
        $paypalForm = $('.paypal-form'),
        // EKPM-1 - Add Klarna (Pay ¾ installment) as a mean of payment
        $klarnaForm = $('.klarna-form');

    preventSubmitClick = false;

    // ECM-880 - Adyen 3DSv2 - reset the issuer value used by some HPP methods
    $('#dwfrm_adyPaydata_issuer').val('');

    //ERB-132 Adyen payment provider
    if (paymentMethodID == 'CREDIT_CARD') {
        if (!formPrepare.hasOwnProperty('adyen_form')) {
            formPrepare.adyen_form = $adyenForm;
        }

        if (typeof formPrepare.adyen_form != 'undefined') {
            // disable normal form submit
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                e.preventDefault();

                // Prevent double clicks
                if (!preventSubmitClick) {
                    preventSubmitClick = true;
                }
                else {
                    return;
                }

                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleCC();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();
                // give time for the $form to be completed properly (adyenCheckoutResult.handleCC)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            var $ccWrapper = $('.cc-wrapper');
            $ccWrapper.append(formPrepare.adyen_form).removeClass('hidden');

            formPrepare.refreshRequiredInputs();

            loadAdyenCreditCardHandler(); // must be done after cc-wrapper is filled
        }
    }
    else {
        if ($adyenForm.length > 0) {
            formPrepare.adyen_form = $adyenForm.remove();
        }

        // gift certificates must have been used to pay for order
        $continueButton.off('click'); // remove any previous listener
        $continueButton.click(function (e) {
            e.preventDefault();

            // Prevent double clicks
            if (preventSubmitClick) {
                return;
            }

            preventSubmitClick = true;

            // force update since it is not a form submit
            if ($phone.parent().hasClass('iti')) {
                $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
            }

            // force the action so Demandware has the correct form handling behavior...
            $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>');
            // permit normal form submit
            $form.off('submit');

            showLoader();

            window.setTimeout(function() {
                $form.submit();
            }, 50);
        });
    }

    //ENPS-8 - Adyen cartridge upgrade - Giropay payment provider
    if (paymentMethodID == 'ADYEN_GIROPAY') {
        if (!formPrepare.hasOwnProperty('giropay_form')) {
            formPrepare.giropay_form = $giropayForm;
        }

        if (typeof formPrepare.giropay_form != 'undefined') {
            // disable normal form submit
            // Giropay method is using the redirect form, so no ajax
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                // Prevent double clicks
                if (!preventSubmitClick) {
                    preventSubmitClick = true;
                }
                else {
                    e.preventDefault();
                    return;
                }

                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleHPP();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();

                // give time for the $form to be completed properly (adyenCheckoutResult.handlePP)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            $('.giropay-wrapper').append(formPrepare.giropay_form).removeClass('hidden');

            loadAdyenHppHandler();
        }
    }
    else {
        if ($giropayForm.length > 0) {
            formPrepare.giropay_form = $giropayForm.remove();
        }
    }

    //ENPS-8 - Adyen cartridge upgrade - Sofort payment provider
    if (paymentMethodID == 'ADYEN_SOFORT') {
        if (!formPrepare.hasOwnProperty('sofort_form')) {
            formPrepare.sofort_form = $sofortForm;
        }

        if (typeof formPrepare.sofort_form != 'undefined') {
            // disable normal form submit
            // Sofort method is using the redirect form, so no ajax
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                // Prevent double clicks
                if (!preventSubmitClick) {
                    preventSubmitClick = true;
                }
                else {
                    e.preventDefault();
                    return;
                }

                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleHPP();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();

                // give time for the $form to be completed properly (adyenCheckoutResult.handlePP)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            $('.sofort-wrapper').append(formPrepare.sofort_form).removeClass('hidden');

            loadAdyenHppHandler();
        }
    }
    else {
        if ($sofortForm.length > 0) {
            formPrepare.sofort_form = $sofortForm.remove();
        }
    }

    //ENPS-8 - Adyen cartridge upgrade - iDeal payment provider
    if (paymentMethodID == 'ADYEN_IDEAL') {
        if (!formPrepare.hasOwnProperty('ideal_form')) {
            formPrepare.ideal_form = $idealForm;
        }

        if (typeof formPrepare.ideal_form != 'undefined') {
            // disable normal form submit
            // iDeal method is using the redirect form, so no ajax
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                // Prevent double clicks
                if (!preventSubmitClick) {
                    preventSubmitClick = true;
                }
                else {
                    e.preventDefault();
                    return;
                }

                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleHPP();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();

                // give time for the $form to be completed properly (adyenCheckoutResult.handlePP)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            $('.ideal-wrapper')
                .prepend(formPrepare.ideal_form).removeClass('hidden') // use prepend as the <div> is not empty, see paymentmethods.isml
                .find('label').on('click', function (e) {
                    e.preventDefault();
                    $('#' + $(this).attr('for')).trigger('click');
                });

            formPrepare.refreshRequiredInputs();

            loadAdyenHppHandler();
        }
    }
    else {
        if ($idealForm.length > 0) {
            formPrepare.ideal_form = $idealForm.remove();
        }
    }

    //ENPS-8 - Adyen cartridge upgrade - Bancontact payment provider
    if (paymentMethodID == 'ADYEN_BANCONTACT') {
        if (!formPrepare.hasOwnProperty('bancontact_form')) {
            formPrepare.bancontact_form = $bancontactForm;
        }

        if (typeof formPrepare.bancontact_form != 'undefined') {
            // disable normal form submit
            // Bancontact method is using the redirect form, so no ajax
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                // Prevent double clicks
                if (!preventSubmitClick) {
                    preventSubmitClick = true;
                }
                else {
                    e.preventDefault();
                    return;
                }

                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleHPP();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();

                // give time for the $form to be completed properly (adyenCheckoutResult.handlePP)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            $('.bancontact-wrapper')
                .append(formPrepare.bancontact_form).removeClass('hidden')
                .find('label').on('click', function (e) {
                    e.preventDefault();
                    $('#' + $(this).attr('for')).trigger('click');
                });

            formPrepare.refreshRequiredInputs();

            loadAdyenHppHandler();
        }
    }
    else {
        if ($bancontactForm.length > 0) {
            formPrepare.bancontact_form = $bancontactForm.remove();
        }
    }

    //ENPS-8 - Adyen cartridge upgrade - EPS payment provider
    if (paymentMethodID == 'ADYEN_EPS') {
        if (!formPrepare.hasOwnProperty('eps_form')) {
            formPrepare.eps_form = $epsForm;
        }

        if (typeof formPrepare.eps_form != 'undefined') {
            // disable normal form submit
            // EPS method is using the redirect form, so no ajax
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                // Prevent double clicks
                if (!preventSubmitClick) {
                    preventSubmitClick = true;
                }
                else {
                    e.preventDefault();
                    return;
                }

                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleHPP();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();

                // give time for the $form to be completed properly (adyenCheckoutResult.handlePP)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            $('.eps-wrapper')
                .append(formPrepare.eps_form).removeClass('hidden')
                .find('label').on('click', function (e) {
                    e.preventDefault();
                    $('#' + $(this).attr('for')).trigger('click');
                });

            formPrepare.refreshRequiredInputs();

            loadAdyenHppHandler();
        }
    }
    else {
        if ($epsForm.length > 0) {
            formPrepare.eps_form = $epsForm.remove();
        }
    }

    if (paymentMethodID == 'ADYEN_WECHAT') {
        if (!formPrepare.hasOwnProperty('wechat_form')) {
            formPrepare.wechat_form = $wechatForm;
        }

        if (typeof formPrepare.wechat_form != 'undefined') {
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleHPP();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();

                // give time for the $form to be completed properly (adyenCheckoutResult.handlePP)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            $('.wechat-wrapper').append(formPrepare.wechat_form).removeClass('hidden');

            loadAdyenHppHandler();
        }
    }
    else {
        if ($wechatForm.length > 0) {
            formPrepare.wechat_form = $wechatForm.remove();
        }
    }

    if (paymentMethodID == 'ADYEN_ALIPAY') {
        if (!formPrepare.hasOwnProperty('alipay_form')) {
            formPrepare.alipay_form = $alipayForm;
        }

        if (typeof formPrepare.alipay_form != 'undefined') {
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleHPP();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();

                // give time for the $form to be completed properly (adyenCheckoutResult.handlePP)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            $('.alipay-wrapper').append(formPrepare.alipay_form).removeClass('hidden');

            loadAdyenHppHandler();
        }
    }
    else {
        if ($alipayForm.length > 0) {
            formPrepare.alipay_form = $alipayForm.remove();
        }
    }

    if (paymentMethodID == 'ADYEN_PAYPAL') {
        if (!formPrepare.hasOwnProperty('paypal_form')) {
            formPrepare.paypal_form = $paypalForm;
        }

        if (typeof formPrepare.paypal_form != 'undefined') {
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleHPP();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();

                // give time for the $form to be completed properly (adyenCheckoutResult.handlePP)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            $('.paypal-wrapper').append(formPrepare.alipay_form).removeClass('hidden');

            loadAdyenHppHandler();
        }
    }
    else {
        if ($alipayForm.length > 0) {
            formPrepare.alipay_form = $alipayForm.remove();
        }
    }

    // EKPM-1 - Add Klarna (Pay ¾ installment) as a mean of payment
    if (paymentMethodID == 'ADYEN_KLARNA') {
        if (!formPrepare.hasOwnProperty('klarna_form')) {
            formPrepare.klarna_form = $klarnaForm;
        }

        if (typeof formPrepare.klarna_form != 'undefined') {
            // disable normal form submit
            // Klarna method is using the redirect form, so no ajax
            $continueButton.off('click'); // remove any previous listener
            $continueButton.click(function (e) {
                // Prevent double clicks
                if (!preventSubmitClick) {
                    preventSubmitClick = true;
                }
                else {
                    e.preventDefault();
                    return;
                }

                // force update since it is not a form submit
                if ($phone.parent().hasClass('iti')) {
                    $phone.siblings('input').val(window.intlTelInputGlobals.getInstance($phone[0]).getNumber());
                }

                var adyenCheckoutResult = adyenCheckout.handleHPP();
                if (!adyenCheckoutResult) {
                    preventSubmitClick = false;
                    return false;
                }

                $form.append('<input type="hidden" name="dwfrm_billing_save" value="save"/>'); // force the action so Demandware has the correct form handling behavior...
                $form.off('submit'); // permit normal form submit

                showLoader();

                // give time for the $form to be completed properly (adyenCheckoutResult.handlePP)
                window.setTimeout(function() {
                    $form.submit();
                }, 50);
            });

            $('.klarna-wrapper').append(formPrepare.klarna_form).removeClass('hidden');

            loadAdyenHppHandler();
        }
    }
    else {
        if ($klarnaForm.length > 0) {
            formPrepare.klarna_form = $klarnaForm.remove();
        }
    }

    var $paymentMethods = $('.payment-method').removeClass('payment-method-expanded'),
        $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');

    $selectedPaymentMethod.addClass('payment-method-expanded');

    formPrepare.validateForm(); //init the form validation again so the submit listener is active

    // reset validation
    var validator = $form.validate();
    if (validator) {
        validator.resetForm();
    }

    $selectedPaymentMethod.find('.gl_has-error').removeClass('gl_has-error');

    // Initialize the jQuery UI elements
    window.vbqUtils.initializeJQueryUiPlugins();

    window.vbqUtils.initFloatingLabels($form);

    //ERV-485 - Checkout - Billing and Payment - Guest - Logic improvements for validation
    window.vbqUtils.validationLogic.formValidation($form);
}

// ERB-132 - Adyen payment provider
/**
 * @function
 * @description Changes the payment type to the selected payment method
 * @param {String, Boolean} value of payment type or issuerId and a test value to see which one it is, to which the payment type or issuerId should be changed to
 */
/*
function updatePaymentType(selectedPayType) {
    // ECM-880 - Adyen 3DSv2 - updated logic
    if (issuerType) {
        $('input[name="issuer"]').val(selectedPayType);
    }
    else {
        $('input[name="brandCode"]').removeAttr('checked');
        $('input[value="' + selectedPayType + '"]').prop('checked', true);
    }

    formPrepare.validateForm();
}
*/

//ECM-880 - Adyen 3DSv2 - new logic
/**
* @function
* @description Adyen - Initializes the visibility of HPP fields
*/
/*
function initializeHPPFields () {
    if ($('[name="brandCode"]:checked').hasClass('openInvoice')) {
        $('.additionalfield').hide().find('input').val('');
        $('.additionalfield.' + $('.checkout-billing').find('select.country').val()).show();
    }
    else {
        $('.additionalfield').hide().find('input').val('');
    }
}
*/

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    $form = $('form[id$="billing"]');
    var $checkoutForm = $('.checkout-billing'),
        $addGiftCert = $('.js-add-giftcert'),
        $removeGiftCert = $('.js-checkout-giftcert-remove'),
        $giftCertBalance = $('.js-giftcert-balance'),
        $addGiftCertError = $checkoutForm.find('.js-giftcert-error'),
        $giftCertCode = $('input[name$="_giftCertCode"]'),
        $checkoutGiftcertLoader = $('.js-checkout-giftcert-loader');
    //var $addCoupon = $('#add-coupon');
    //var $couponCode = $('input[name$="_couponCode"]');
    var selectedPaymentMethod = $form.find('.payment-method-options').find(':checked').val(),
        $salesRules = $('#salesRules'),
        $salesRulesLabel = $('.sales-rules-label');
        // ECM-880 - Adyen 3DSv2 - new logic
        /* incompatible with customization
        $payType = $('[name="brandCode"]'),
        $issuer = $('.issuer'),
        selectedPayType = $payType.find(':checked').val();
        selectedIssuerId = $issuerId.find(':checked').val();
        */

    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });

    $continueButton = $('#js-specificProcessOnSubmit');
    $loader = $('#js-ajaxLoader');
    $ajaxLoading = $('#js-ajaxLoading');

    $phone = $form.find('.phone_field');
    window.vbqUtils.phoneCodes.init($form.find('.country_field'), $phone);

    if (selectedPaymentMethod) {
        updatePaymentMethod(selectedPaymentMethod);
    }
    else {
        // ECM-296 - Payment Methods - Display order
        // The default method has to be Adyen credit card
        var $adyenForm = $('.payment-method').filter('[data-method="CREDIT_CARD"]'),
            defaultMethod = $adyenForm.length > 0 ? 'CREDIT_CARD' : '';

        $('#is-' + defaultMethod).prop('checked', true).checkboxradio('refresh');
        updatePaymentMethod(defaultMethod);
    }

    $form.on('change', '.payment-method-selector', function () {
        var val = $(this).data('payment');
        updatePaymentMethod(val);

        // ECM-880 - Adyen 3DSv2 - new logic
        /* incompatible with customization
        if ($(this).val() == 'Adyen' && $payType.length > 0) {
            // set payment type of Adyen to the first one
            updatePaymentType((selectedPayType) ? selectedPayType : $payType[0].value, false);
        }
        else {
            $payType.removeAttr('checked');
        }
        */
    });

    $salesRulesLabel.on('click', function (e) {
        e.preventDefault();
        $('#' + $(this).attr('for')).trigger('click');
    });

    // EADA23-5 - Add errors to identified to screen reader users.
    var balanceErrorIds = $giftCertBalance.map(function () { return $(this).attr('id') || ''; }).get().join(' '),
        addGiftCertErrorId = $addGiftCertError.attr('id');

    $('.js-check-giftcert').on('click', function (e) {
        e.preventDefault();
        var // clean from balance error ids
            giftCertCodeAriaDescribedBy = $.trim(($giftCertCode.attr('aria-describedby') || '').replace(addGiftCertErrorId, '')),
            nextGiftCertCodeAriaDescribedBy = giftCertCodeAriaDescribedBy.indexOf(balanceErrorIds) == -1 ? $.trim(giftCertCodeAriaDescribedBy + ' ' + balanceErrorIds) : giftCertCodeAriaDescribedBy;

        window.vbqUtils.progressLoader(true, $checkoutGiftcertLoader);

        // clear any previous success or error messages
        $giftCertBalance.html('').removeClass('success').removeClass('error');
        // clear any previous error messages from 'redeem' action if any
        $addGiftCertError.html('').addClass('hidden');

        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            $giftCertBalance.html(Resources.GIFT_CERT_MISSING).removeClass('success').addClass('error');
            $giftCertCode.attr('aria-describedby', nextGiftCertCodeAriaDescribedBy);
            window.vbqUtils.progressLoader(false, $checkoutGiftcertLoader);

            // EADA23-5 ADA Focus on the input field if error message is displayed
            $giftCertCode.focus();

            return;
        }

        giftcard.checkBalance($giftCertCode.val(), function (response) {
            if (response && response.error) {
                $giftCertBalance.html(response.message).removeClass('success').addClass('error');
                $giftCertCode.attr('aria-describedby', nextGiftCertCodeAriaDescribedBy);
                window.vbqUtils.progressLoader(false, $checkoutGiftcertLoader);

                // EADA23-5 ADA Focus on the input field if error message is displayed
                $giftCertCode.focus();

                return;
            }

            if (response && response.value) {
                $giftCertBalance.html(Resources.GIFT_CERTIFICATE_BALANCE.replace('{0}', response.value));
                $giftCertCode.attr('aria-describedby', $.trim(giftCertCodeAriaDescribedBy.replace(balanceErrorIds, '')));
                window.vbqUtils.progressLoader(false, $checkoutGiftcertLoader);

                return;
            }

            // show technical error message
            $giftCertBalance.html(Resources.GIFT_CERTIFICATE_ERROR).removeClass('success').addClass('error');
            $giftCertCode.attr('aria-describedby', nextGiftCertCodeAriaDescribedBy);
            window.vbqUtils.progressLoader(false, $checkoutGiftcertLoader);

            // EADA23-5 ADA Focus on the input field if error message is displayed
            $giftCertCode.focus();
        });
    });

    $addGiftCert.on('click', function (e) {
        e.preventDefault();

        var code = $giftCertCode.val(),
            // clean from balance error ids
            giftCertCodeAriaDescribedBy = $.trim(($giftCertCode.attr('aria-describedby') || '').replace(balanceErrorIds, '')),
            nextGiftCertCodeAriaDescribedBy = giftCertCodeAriaDescribedBy.indexOf(addGiftCertErrorId) == -1 ? $.trim(giftCertCodeAriaDescribedBy + ' ' + addGiftCertErrorId) : giftCertCodeAriaDescribedBy;

        if (code.length === 0) {
            $addGiftCertError.html(Resources.GIFT_CERT_MISSING).removeClass('hidden');
            $giftCertCode.attr('aria-describedby', nextGiftCertCodeAriaDescribedBy);

            // EADA23-5 ADA Focus on the input field if error message is displayed
            $giftCertCode.focus();

            // clear any previous success or error messages from 'check balance' action if any
            $giftCertBalance.html('').removeClass('success').removeClass('error');

            return;
        }

        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: 'ajax'});

        window.vbqUtils.progressLoader(true, $checkoutGiftcertLoader);
        $.ajax({
            url: url
        }).done(function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            }
            else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }

            if (fail) {
                $addGiftCertError.html(msg).removeClass('hidden');
                $giftCertCode.attr('aria-describedby', nextGiftCertCodeAriaDescribedBy);

                // clear any previous success or error messages from 'check balance' action if any
                $giftCertBalance.html('').removeClass('success').removeClass('error');
                window.vbqUtils.progressLoader(false, $checkoutGiftcertLoader);

                // EADA23-5 ADA Focus on the input field if error message is displayed
                $giftCertCode.focus();

                return;
            }
            else {
                window.location.assign(Urls.billing);
            }
        }).fail(function () {
            window.vbqUtils.progressLoader(false, $checkoutGiftcertLoader);
            window.alert(Resources.CHECKOUT_AJAX_ERROR);
        });
    });

    $removeGiftCert.on('click', function (e) {
        window.vbqUtils.progressLoader(true, $checkoutGiftcertLoader);
    });

/* ERB-52 International Shipping
       deactivate coupon field
    $addCoupon.on('click', function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error'),
            code = $couponCode.val();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.ajax({
            url: url
        }).done(function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            }
            else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success && data.baskettotal === 0) {
                window.location.assign(Urls.billing);
            }
        }).fail(function () {
            window.alert(Resources.CHECKOUT_AJAX_ERROR);
        });
    });

    // trigger events on enter
    $couponCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });
    */

    $giftCertCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });

    // gift certification accordion on checkout in billing section
    var $giftcertAccordion = $('.js-giftcert-accordion');

    if ($giftcertAccordion.hasClass('expanded')) {
        $giftcertAccordion.customAccordion().customAccordion('active', 0);
    }
    else {
        $giftcertAccordion.customAccordion();
    }

    // ECM-880 - Adyen 3DSv2 - new logic start
    /* not compatible with customization, must be called only if method is CREDIT_CARD
    if (SitePreferences.ADYEN_SF_ENABLED) {
        adyenCheckout.initBilling();
    }
    */

    // Adyen - Click event for payment methods
    /* incompatible with customization where HPP methods are dynamically loaded, displayed one by one and not grouped
    $payType.on('click', function () {
        updatePaymentType($(this).val(), false);
        $issuer.hide();
        $('[name="issuer"]').val("");
        //if the payment type contains issuerId fields, expand form with the values
        if ($(this).siblings('.issuer').length > 0) {
            updatePaymentType($(this).siblings('.issuer').find('option').first().val(), true);
            $(this).siblings('.issuer').show();
        }
        initializeHPPFields();
    });
    */
    // ECM-880 - Adyen 3DSv2 - new logic end

    // force required state for the sales rules checkbox so validationLogic.js code works as expected
    $('#salesRules').rules('add', {
        required: true
    });

    // Prevent enter key submit and follow the normal submit button logic
    $form.on('submit', function (e) {
        e.preventDefault();

        if ($continueButton.length > 0 && $continueButton.is(':not(:disabled)')) {
            $continueButton.trigger('click');
        }
    });

    // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
    // Update the billing form
    $form.on('change', '[name$="_addressFields_country"]', function () {
        var country = $(this).val();

        if (!onlyUpdatePaymentMethods) { // no need to update the billing address on page load
            showLoader();
            $.ajax({
                url: util.appendParamsToUrl(Urls.updateBillingAddressForm, {'countryCode': country, 'form': $form.serialize()}),
            }).done(function (response) {
                $form.find('.address-dynamic-form').html(response);

                formPrepare.init({
                    continueSelector: '[name$="billing_save"]',
                    formSelector: '[id$="billing"]'
                });

                // Initialize the jQuery UI elements
                window.vbqUtils.initializeJQueryUiPlugins();
                window.vbqUtils.initFloatingLabels($form);
                $phone = $form.find('.phone_field');
                window.vbqUtils.phoneCodes.init($form.find('.country_field'), $phone);
                hideLoader();

                // update country for googleplaces api
                googleplaces.update({
                    country: country
                });

                // ECM23-119 - USA ZIP Codes & Postal Codes List By States
                // update cached form elements and events
                usaPostalMap.update();
            }).fail(function () {
                window.alert(Resources.CHECKOUT_AJAX_ERROR);
                hideLoader();
            });
        }
        else {
            onlyUpdatePaymentMethods = false;
        }

        // ENPS-8 - Adyen cartridge upgrade - Update the HPP methods
        if ($('#is-CREDIT_CARD').length > 0) {
            showLoader();
            $.ajax({
                url: util.appendParamsToUrl(Urls.updateHppMethods, {'countryCode': country}),
            }).done(function (response) {
                var hppPreviouslySelected = $('#is-ADYEN_GIROPAY').is(':checked') || $('#is-ADYEN_SOFORT').is(':checked') || $('#is-ADYEN_IDEAL').is(':checked') || $('#is-ADYEN_EPS').is(':checked') || $('#is-ADYEN_BANCONTACT').is(':checked') || $('#is-ADYEN_ALIPAY').is(':checked') || $('#is-ADYEN_WECHAT').is(':checked') || $('#is-ADYEN_PAYPAL').is(':checked') || $('#is-ADYEN_KLARNA').is(':checked');

                // bank list for method, if any, is returned with the available payment methods
                // remove previous one
                $('#type').remove(); // ECM-880 TODO new logic
                $form.find('.js-issuersList').remove();

                var $resp = $('<div/>').append(response);

                $form.find('#js-paymentMethodChoices')
                    .find('.hpp-method').remove().end() // remove previous Adyen HPP methods
                    .append($resp.html())
                    .find('.hpp-method.hidden').removeClass('hidden');

                if ($form.find('#bcmc-possible').length) {
                    $form.find('.js-bancontact_icon.hidden').removeClass('hidden');
                }
                else {
                    $form.find('.js-bancontact_icon').addClass('hidden');
                }

                // Initialize the jQuery UI elements
                window.vbqUtils.initializeJQueryUiPlugins();

                // move issuers to payment method corresponding form
                var $issuers = $form.find('.js-issuersList');
                if ($issuers.length > 0) {
                    var target = $issuers.attr('data-target');
                    // issuers list code is only meant for EPS for now (iDeal is using the "checkoutComponent.isml" include
                    if (target == 'ADYEN_EPS') {
                        // use the pointer to $epsForm as it can be outside of the DOM
                        formPrepare.eps_form.append($issuers.removeClass('hidden'));
                    }
                }

                // Default to CREDIT_CARD if the country is updated and Adyen was previously checked
                if (hppPreviouslySelected) {
                    $('#is-CREDIT_CARD').prop('checked', true).trigger('change');
                    updatePaymentMethod('CREDIT_CARD');
                }

                hideLoader();
            }).fail(function () {
                window.alert(Resources.CHECKOUT_AJAX_ERROR);
                hideLoader();
            });
        }
    });

    // ENPS-8 - Adyen cartridge upgrade - Functions to handle the main/HPP payment selections and force dynamic display depending on the billing country on load
    var onlyUpdatePaymentMethods = true;
    $form.find('[name$="_addressFields_country"]').trigger('change');

    //ERB-51 - SEO Tracking - Google Tag Manager - push GA event
    $continueButton.on('click', function () {
        // ECM24-8 - Datalayer update (GA4 GTM)
        sendGtmPaymentInfoEvent();
    });

    // initialize the googleplaces api only when the form is visible
    // here it will load if the "same as shipping address" checkbox is not selected during shipping address setup
    if ($('#js-billingAddressForm').is(':visible')) {
        var countryField = document.querySelector('[name$="_addressFields_country"]'),
            // use the current set country from the billing form
            countryCode = countryField && countryField.value || '';
        googleplaces.init({
            country: countryCode
        });

        // ECM23-119 - USA ZIP Codes & Postal Codes List By States
        usaPostalMap.init($form);
    }

    $('#js-billingAddressFormEdit').on('click', function (e) {
        e.preventDefault();

        $(this).parent().addClass('hidden');
        $('#js-billingAddressForm').removeClass('hidden').siblings('.js-summary').addClass('hidden');

        // EADA23-84 - hide email mandatory as it presents for the whole billing address form in edit mode
        $('#js-billingAddressEmailMandatory').addClass('hidden');

        // initialize the googleplaces api only when the form is visible
        var countryField = document.querySelector('[name$="_addressFields_country"]'),
            // use the current set country from the billing form
            countryCode = countryField && countryField.value || '';
        googleplaces.init({
            country: countryCode
        });

        // ECM23-119 - USA ZIP Codes & Postal Codes List By States
        usaPostalMap.init($form);
    });
};

// ECM-880 - Adyen 3DSv2 - new logic, updated to be compatible with multiple payment methods
var _adyenCheckoutScriptLoaded = false;
function loadAdyenCreditCardHandler () {
    var init = function () {
        $('#card').empty();
        initializeCardComponent(window.vbqUtils.adyen.originKey, window.vbqUtils.adyen.environment, window.vbqUtils.adyen.locale);
    };

    if (!_adyenCheckoutScriptLoaded) {
        var scriptTag = document.createElement('script');
        scriptTag.src = window.vbqUtils.adyen.scriptSrc;
        scriptTag.type = 'text/javascript';
        document.body.appendChild(scriptTag);

        scriptTag.onload = function () {
            _adyenCheckoutScriptLoaded = true;

            init();
        };
    }
    else {
        // security step, need to "destroy" the previous fields
        window.AdyenCard.unmount(document.getElementById('card'));
        init();
    }

    function initializeCardComponent(originKey, environment, locale) {
        var MASKED_CC_PREFIX = '************';
        window.Configuration = {
            locale: locale,
            originKey: originKey,
            environment: environment
        };

        var AdyenCheckoutObject = new window.AdyenCheckout(window.Configuration),
            styleObject = {
                base: {
                    color: window.vbqUtils.isSR ? '#000000' : '#0c315d',
                    fontSize: '11px',
                    fontSmoothing: 'antialiased',
                    fontFamily: 'Arial'
                },
                error: {
                    color: window.vbqUtils.isSR ? '#d10244' : 'red'
                },
                placeholder: {
                    color: '#d8d8d8'
                },
                validated: {
                    color: window.vbqUtils.isSR ? '#000000' : '#1b65b1'
                }
            };

        window.CardValid = false;
        window.AdyenCard = AdyenCheckoutObject.create('card', {
            type: 'card',
            hasHolderName: true,
            holderNameRequired: true,
            groupTypes: ['visa', 'mc', 'amex', 'bcmc'],
            enableStoreDetails: false, // window.showStoreDetails, NOT WANTED
            styles: styleObject,
            placeholders: {
                encryptedCardNumber : '4111 1111 1111 1111',
                encryptedExpiryDate : '03/30',
                encryptedSecurityCode : '737'
            },
            onChange: function (state) {
                // checks whether card was valid then was changed to be invalid
                window.CardValid = state.isValid;
                window.storeDetails = state.data.storePaymentMethod;
                $('#dwfrm_billing_paymentMethods_creditCard_browserInfo').val(JSON.stringify(state.data.browserInfo));
            },
            onFieldValid: function (data) {
                if (data.endDigits) {
                    $('#dwfrm_billing_paymentMethods_creditCard_number').val(MASKED_CC_PREFIX + data.endDigits);
                }
            },
            // not in the original cartridge code but necessary for SFCC billing form validity
            onBrand: function (state) {
                // window.AdyenCard.brand -> KO (AdyenCard is not an object)
                // window.AdyenCard.state.brand -> KO (state is not an object)
                // window.AdyenCard.state.data.brand -> KO (data undefined)
                // so using new variable
                window.AdyenCardBrand = state.brand;
            },
            onConfigSuccess: function () {
                /* ECM24-13 - Remove CSV text in the card field
                // add CVV tooltip
                var code = '<div class="cvv-tooltip-wrapper">';
                code += '<button class="cvv-tooltip tooltip">';
                code += window.Resources.CVV_TOOLTIP_TITLE;
                code += '<div class="tooltip-content" data-layout="small tooltip-cvv">';
                code += window.Resources.CVV_TOOLTIP_EXPLANATION;
                code += '</div></button></div>';

                $('.adyen-checkout__field__cvc').find('.adyen-checkout__label').append(code);

                $('.adyen-checkout__card__holderName').find('.adyen-checkout__label__text').text(function (i, v) { return v + ' ' + window.Resources.CARDHOLDERNAME_ADDITIONAL; });
                */
            }
        });

        window.AdyenCard.mount(document.getElementById('card'));
    }
}

var _adyenHppScriptLoaded = false;
function loadAdyenHppHandler () {
    var initHpp = function () {
        window.dfDo('dwfrm_adyPaydata_adyenFingerprint');
    };

    if (!_adyenHppScriptLoaded) {
        var scriptTag = document.createElement('script');
        scriptTag.src = 'https://live.adyen.com/hpp/js/df.js?v=' + window.vbqUtils.sessionID;
        scriptTag.type = 'text/javascript';
        document.body.appendChild(scriptTag);

        scriptTag.onload = function () {
            _adyenHppScriptLoaded = true;

            initHpp();
        };
    }
    else {
        initHpp();
    }


    var initCheckout = function () {
        window.Configuration = {
            locale: window.vbqUtils.adyen.locale,
            originKey: window.vbqUtils.adyen.originKey,
            environment: window.vbqUtils.adyen.environment,
            risk: {
                enabled: false
            }
        };

        var AdyenCheckoutObject = new window.AdyenCheckout(window.Configuration);
    };

    if (!_adyenCheckoutScriptLoaded) {
        var scriptOtherTag = document.createElement('script');
        scriptOtherTag.src = window.vbqUtils.adyen.scriptSrc;
        scriptOtherTag.type = 'text/javascript';
        document.body.appendChild(scriptOtherTag);

        scriptOtherTag.onload = function () {
            _adyenCheckoutScriptLoaded = true;

            initCheckout();
        };
    }
    else {
        initCheckout();
    }
}

// ECM-880 - Adyen 3DSv2 - code copied from checkoutComponent.isml and adapted so everything is dynamic and can handle the multiple payment providers
window.checkoutComponent = function (methodType, methodDetails) {
    switch (methodType) {
        case 'ideal':
            var paymentMethodDetails = JSON.parse(methodDetails.replace(/&quot;/g, '\"')),
                component = document.getElementById('component_ideal'),
                idealContainer = document.createElement('div');

            var initIdeal = function () {
                window.Configuration = {
                        locale: window.vbqUtils.adyen.locale,
                        originKey: window.vbqUtils.adyen.originKey,
                        environment: window.vbqUtils.adyen.environment,
                        risk: {
                            enabled: false
                        }
                    };

                    var AdyenCheckoutObject = new window.AdyenCheckout(window.Configuration);
                    // idealComponent is used in adyen-checkout.js, must be global
                    window.idealComponent = AdyenCheckoutObject.create('ideal', {
                        details: paymentMethodDetails.details
                    });

                    component.append(idealContainer);
                    window.idealComponent.mount(idealContainer);
            };

            if (!_adyenCheckoutScriptLoaded) {
                var scriptTag = document.createElement('script');
                scriptTag.src = window.vbqUtils.adyen.scriptSrc;
                scriptTag.type = 'text/javascript';
                document.body.appendChild(scriptTag);

                scriptTag.onload = function () {
                    _adyenCheckoutScriptLoaded = true;

                    initIdeal();
                };
            }
            else {
                initIdeal();
            }

            break;
         /* not used
         case "klarna":
             var paymentMethodDetails = JSON.parse("${openInvoiceMethodDetails}".replace(/&quot;/g, '\"'));
             if(paymentMethodDetails){
                 var component = document.getElementById("component_klarna");
                var klarnaContainer = document.createElement("div");
                   var klarnaComponent = AdyenCheckoutObject.create('klarna', {
                       details: paymentMethodDetails,
                       visibility: {
                               personalDetails: "editable"
                       },
                   });
                   component.append(klarnaContainer);
                   klarnaComponent.mount(klarnaContainer);

                 var billingCountry = document.getElementsByClassName("input-select country")[0].value;
                 if(isNordicCountry(billingCountry.toUpperCase())){
                     var ssnContainer = document.createElement("div");
                     ssnContainer.setAttribute("id", "ssn_klarna");
                     var socialSecurityNumberLabel = document.createElement("span");
                     socialSecurityNumberLabel.textContent = "Social Security Number";
                     var socialSecurityNumber = document.createElement("input");
                     socialSecurityNumber.setAttribute("id", "ssnValue");
                     socialSecurityNumber.setAttribute("class", "adyen-checkout__input");
                     socialSecurityNumber.setAttribute("type", "text");

                     ssnContainer.append(socialSecurityNumberLabel);
                     ssnContainer.append(socialSecurityNumber);
                     klarnaContainer.append(ssnContainer);
                 }
             }
            break;
         case "afterpay_default":
             var paymentMethodDetails = JSON.parse("${openInvoiceMethodDetails}".replace(/&quot;/g, '\"'));
             var component = document.getElementById("component_${method.type}");
            var afterpayContainer = document.createElement("div");
               var afterpayComponent = AdyenCheckoutObject.create('afterpay', {
                   details: paymentMethodDetails,
                   visibility: {
                           personalDetails: "editable"
                   },
               });
               component.append(afterpayContainer);
               afterpayComponent.mount(afterpayContainer);
            break;
         */
         default:
            //no component
    }

    /* not used
    function isNordicCountry(country){
        if(country === "SE"|| country === "FI" || country === "DK" || country === "NO") {
            return true;
        }
        return false;
    }
    */
};
