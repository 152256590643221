'use strict';

const loadScript = require('./helpers/loadScript'),
    mexicoStatesMapping = require('./helpers/mexicoStatesMapping'),
    cache = {};

/**
 * Initializes the cache object with references to DOM elements and address components.
 * This function sets up the cache with form elements and address fields, as well as mappings
 * for address components to their respective form fields.
 */
function initCache() {
    cache.document = document;
    cache.form = cache.document.querySelector('.js-form_autocomplete');
    cache.dom = {
        address1: cache.form.querySelector('[name$="_addressFields_address1"]') || cache.form.querySelector('[name$="_address_address1"]'),
        address2: cache.form.querySelector('[name$="_addressFields_address2"]') || cache.form.querySelector('[name$="_address_address2"]'),
        city: cache.form.querySelector('[name$="_addressFields_city"]') || cache.form.querySelector('[name$="_address_city"]'),
        postalcode: cache.form.querySelector('[name$="_addressFields_postal"]') || cache.form.querySelector('[name$="_address_postal"]'),
        state: cache.form.querySelector('[name$="_addressFields_states_state"]') || cache.form.querySelector('[name$="_address_states_state"]')
    };

    // google places address components which will be used
    cache.addressComponents = {
        street_number: {},
        route: {},
        subpremise: {},
        locality: {},
        postal_town: {},
        administrative_area_level_1: {},
        administrative_area_level_2: {},
        postal_code: {}
    };

    // mappings for address components to form fields
    cache.fieldsMapping = {
        address1: { value: ['route.long_name', 'street_number.short_name'] },
        address2: { value: ['subpremise.long_name'] },
        city: {
            value: ['locality.long_name'],
            UK: ['postal_town.long_name'],
            GB: ['postal_town.long_name'],
            EG: ['administrative_area_level_1.short_name'],
            KR: ['administrative_area_level_1.short_name']
        },
        postalcode: { value: ['postal_code.long_name'] },
        state: { value: ['administrative_area_level_1.short_name'] }
    };
}

/**
 * Initializes the data required for Google Places API integration.
 *
 * @param {Object} config - Configuration object for initializing data.
 * @param {string} config.country - The country code to set the region for the API request.
 * @param {HTMLElement} [config.input] - The input element for Google Places API, defaults to the element with id 'js-googleplaces_api'.
 */
function initData(config) {
    cache.request = {
        input: '',
        region: config.country || '',
        includedRegionCodes: [config.country || '']
    };

    const lang = cache.document.documentElement.getAttribute('lang');
    cache.url = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCh7GmqJgvQ54eHgpjSRwB33b3JYHDbkGM&libraries=places,geometry&loading=async&callback=initAutocomplete&language=${lang}`;

    cache.input = config.input || cache.document.getElementById('js-googleplaces_api');
    cache.googleplacesWrapper = cache.document.getElementById('js-googleplaces_wrapper');
    cache.results = cache.document.getElementById('js-googleplaces_results');
    cache.country = config.country;
}

/**
 * Makes an asynchronous request to fetch autocomplete suggestions based on user input.
 * Updates the DOM with the fetched suggestions.
 *
 * @async
 * @param {Event} input - The input event triggered by the user.
 * @returns {Promise<void>} A promise that resolves when the autocomplete suggestions have been processed and the DOM updated.
 */
async function makeAcRequest(input) {
    // if the input is empty, clear the results and return
    if (input.target.value === '') {
        cache.results.replaceChildren();
        cache.results.dispatchEvent(new CustomEvent('googleplaces:updated', { bubbles: true }));

        return;
    }

    cache.request.input = input.target.value;

    // fetch autocomplete suggestions
    const { suggestions } = await window.google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(cache.request);

    // clear the results and populate with the new suggestions
    cache.results.replaceChildren();

    suggestions.forEach(suggestion => {
        const placePrediction = suggestion.placePrediction;
        const suggestionButton = document.createElement('button');

        suggestionButton.classList.add('googleplaces-btn', 'gl_fake-link');
        suggestionButton.addEventListener('click', () => onPlaceSelected(placePrediction.toPlace()));
        suggestionButton.innerText = placePrediction.text.toString();

        const li = document.createElement('li');
        li.classList.add('googleplaces-li');
        li.appendChild(suggestionButton);
        cache.results.appendChild(li);
    });

    cache.results.dispatchEvent(new CustomEvent('googleplaces:updated', { bubbles: true }));
}

/**
 * Updates form fields based on the searched phrase and cached address components.
 *
 * This function iterates over the keys in the cache.dom object and updates the corresponding form fields
 * with values derived from the cached address components. Special handling is applied for the 'state' field
 * when the country is 'MX' (Mexico) to map state abbreviations. If the 'address1' field is empty after the
 * iteration, it is set to the searched phrase.
 *
 * @param {string} searchedPhrase - The phrase that was searched, used to populate the 'address1' field if it is empty.
 */
function updateFormFields(searchedPhrase) {
    Object.keys(cache.dom).forEach(key => {
        // skip the state field if the country is not US, MX, CA, or AU
        if (key === 'state' && !['US', 'MX', 'CA', 'AU'].includes(cache.country)) return;

        // get value for the current field from the cached address components
        const fieldMapping = cache.fieldsMapping[key];
        const fieldValueArray = fieldMapping[cache.country] || fieldMapping.value || [];
        let fieldValue = fieldValueArray.map(fieldKey => {
            const [key, subKey] = fieldKey.split('.');
            return cache.addressComponents[key][subKey] || '';
        }).filter(value => value.trim()).join(', ');

        // special handling for the state field when the country is MX
        if (key === 'state' && cache.country === 'MX') {
            fieldValue = mexicoStatesMapping[fieldValue];
        }

        // special handling for the city field when the values was not found
        if (key === 'city' && !fieldValue) {
            fieldValue = cache.addressComponents.administrative_area_level_2.short_name || '';
        }

        cache.dom[key].value = fieldValue;
        cache.dom[key].focus();
        cache.dom[key].blur();
    });

    // if the address1 field is empty, set it to the searched phrase
    if (!cache.dom.address1.value) {
        const maxlength = cache.dom.address1.getAttribute('maxlength') || 35;
        cache.dom.address1.value = searchedPhrase.slice(0, maxlength);
        cache.dom.address1.focus();
        cache.dom.address1.blur();
    }
}

/**
 * Clears the search results and resets the input field.
 * This function removes all child elements from the results container and clears the input field value.
 */
function clearResults() {
    cache.results.replaceChildren();
    cache.results.dispatchEvent(new CustomEvent('googleplaces:updated', { bubbles: true }));
    cache.input.value = '';
}

/**
 * Refreshes the session token for a Google Places Autocomplete request.
 *
 * This function generates a new AutocompleteSessionToken and assigns it to the 
 * sessionToken property of the provided request object. This is typically used 
 * to ensure that each autocomplete session is unique and to improve the accuracy 
 * of place predictions.
 *
 * @param {Object} request - The request object that requires a refreshed session token.
 * @returns {Object} The updated request object with a new session token.
 */
function refreshToken(request) {
    request.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    return request;
}

/**
 * Handles the event when a place is selected from Google Places autocomplete.
 * Fetches the necessary fields from the selected place, updates the address components cache,
 * updates the form fields with the formatted address, and clears the input and results.
 *
 * @param {Object} place - The selected place object from Google Places autocomplete.
 * @param {Function} place.fetchFields - Function to fetch specific fields from the place object.
 * @param {Array} place.addressComponents - Array of address components of the selected place.
 * @param {string} place.formattedAddress - The formatted address of the selected place.
 * @returns {Promise<void>} A promise that resolves when the place fields are fetched and processed.
 */
async function onPlaceSelected(place) {
    await place.fetchFields({ fields: ['formattedAddress', 'addressComponents'] });

    console.log(place.formattedAddress);
    console.log(place.addressComponents);

    Object.keys(cache.addressComponents).forEach(key => {
        cache.addressComponents[key] = {};
    });

    place.addressComponents.forEach(({ Eg, Fg, Gg }) => {
        Eg.forEach(addressType => {
            if (addressType in cache.addressComponents) {
                cache.addressComponents[addressType] = { long_name: Fg, short_name: Gg };
            }
        });
    });

    updateFormFields(place.formattedAddress);
    // clear the input and results
    clearResults();
    cache.request = refreshToken(cache.request);
}

/**
 * Initializes the Google Places Autocomplete functionality.
 * Sets a flag indicating the Google Maps API has loaded, removes any existing
 * input event listener, adds a new input event listener to make autocomplete requests,
 * and refreshes the token for the autocomplete request.
 */
function initAutocomplete() {
    window.vbqUtils._gMapLoaded = true; // avoid loading the same script a second time for storelocator (pick up in-store in checkout)
    // Remove existing event listener if it exists
    cache.input.removeEventListener('input', makeAcRequest);
    // Add new event listener to make autocomplete requests
    cache.input.addEventListener('input', makeAcRequest);
    cache.request = refreshToken(cache.request);
}

/**
 * Checks if Google Places is enabled for a given country code.
 *
 * This function determines whether the Google Places service is enabled 
 * for a specific country by checking if the country code is not included 
 * in the blacklist of countries defined in the site preferences.
 *
 * @param {string} countryCode - The ISO 3166-1 alpha-2 country code to check.
 * @returns {boolean} - Returns true if Google Places is enabled for the given country code, false otherwise.
 */
function isEnabled(countryCode) {
    return !window.SitePreferences.GOOGLE_PLACES_BLACKLIST_COUNTRIES.includes(countryCode);
}

/**
 * Hides the Google Places suggestions dropdown if the input element is cached.
 * Adds the 'hidden' class to the Google Places wrapper element.
 *
 * @returns {void}
 */
function hideGooglePlaces() {
    if (!cache.input) return;
    cache.googleplacesWrapper.classList.add('hidden');
}

/**
 * Displays the Google Places autocomplete suggestions if the input cache is available.
 * Removes the 'hidden' class from the Google Places wrapper element to make it visible.
 *
 * @returns {void}
 */
function showGooglePlaces() {
    if (!cache.input) return;
    cache.googleplacesWrapper.classList.remove('hidden');
}

/**
 * Initializes the Google Places integration based on the provided configuration.
 * If the integration is not enabled for the specified country, it hides the Google Places elements.
 * Otherwise, it sets up the necessary callbacks, initializes cache and data, and shows the Google Places elements.
 * If the Google Maps script is already loaded, it initializes the autocomplete feature directly.
 * Otherwise, it loads the Google Maps script.
 *
 * @param {Object} config - The configuration object for Google Places integration.
 * @param {string} config.country - The country code to check if Google Places is enabled.
 */
function init(config) {
    if (!isEnabled(config.country)) {
        hideGooglePlaces();
        return;
    }

    // required for google maps callback
    window.initAutocomplete = initAutocomplete;

    initCache();
    initData(config);
    showGooglePlaces();

    // if the google maps script is already loaded, initialize the autocomplete feature directly
    if (window.google && window.google.maps) {
        initAutocomplete();
        return;
    }

    // load the google maps script
    loadScript(cache.url);
}

/**
 * Updates the country configuration for Google Places integration.
 * If the specified country is not enabled, hides the Google Places interface.
 * If the Google Maps API is not loaded, initializes it with the given configuration.
 * Otherwise, initializes the cache and data, clears previous results, and shows the Google Places interface.
 * Finally, refreshes the request token.
 *
 * @param {Object} config - The configuration object for Google Places.
 * @param {string} config.country - The country code to be updated.
 */
function updateCountry(config) {
    if (!isEnabled(config.country)) {
        hideGooglePlaces();
        return;
    }

    // if the google maps script is not loaded, initialize it
    if (!window.google || !window.google.maps) {
        init(config);
        return;
    }

    initCache();
    initData(config);
    clearResults();
    showGooglePlaces();

    // refresh the request token
    cache.request = refreshToken(cache.request);
}

module.exports = {
    init,
    update: updateCountry,
    updateCache: initCache
};
