'use strict';

var util = require('./util'),
    bonusProductsView = require('./bonus-products-view'),
    progress = require('./progress');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    shown: false,
    init: function () {
        this.$el = $('#mini-cart');
        this.$link = this.$el.find('.mini-cart-link');
        this.$content = this.$el.find('.mini-cart-content');
        this.$products = this.$el.find('.mini-cart-products');
        this.$productsHeight = this.$products.height();
        this.$minicartWrapper = $('.minicart-wrapper');
        this.$products.css('height', this.$productsHeight + 1); //hack to hide scrollbar if appropriate

        // events

        // ERV-273: Cart page - add message that product has been added to cart
        // Display the mini cart summary only on desktop - mobile tap redirects to the cart page
        var miniCartTotalADA = window.vbqUtils.isSR ? window.vbqUtils.breakpoint.is('fromDesktopLarge') : window.vbqUtils.breakpoint.is('fromDesktop');

        if (miniCartTotalADA) {
            this.$el.find('.mini-cart-total').on('click', function () {
                this.$link.attr('aria-expanded', (this.$link.attr('aria-expanded') == 'true' ? 'false' : 'true'));
                this.$content.toggleClass('active');

                if (this.$content.attr('aria-hidden') == 'true') {
                    this.$content.attr('aria-hidden', 'false').find('a, button').removeAttr('tabindex');
                }
                else {
                    // ECM23-60: minicart content shouldn't be navigated by tab if it's hidden
                    this.$content.attr('aria-hidden', 'true').find('a, button').attr('tabindex', '-1');
                }
            }.bind(this));
        }

        // ECM23-60: minicart content shouldn't be navigated by tab if it's hidden
        // initial minicart content state
        this.$content.find('a, button').attr('tabindex', '-1');

        $('.minicart-close').on('click', function (ev) {
            this.close();
        }.bind(this));

        window.vbqUtils.cache.$body.on('click', function () {
            if (this.shown) {
                this.close();
            }
        }.bind(this));

        window.vbqUtils.cache.$body.on('click', '#js-miniCartCheckoutButton', function () {
            $('#js-miniContinueShoppingButton, #js-miniCartCheckoutButton').addClass('disabled');
            progress.show($('#js-miniCartCheckoutButton'));
        });

        window.vbqUtils.cache.$body.on('click', '#js-miniContinueShoppingButton', function () {
            this.close();
        }.bind(this));
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        this.$el.html(html);
        // ERB 237 Gift box and message - prefix logic will break for >10 messages
        // prevent browser scrolling on add to cart, not needed with sticky header
        //util.scrollBrowser(0);
        this.init();
        this.$link.attr('aria-expanded', 'true');
        this.$content.addClass('active').attr('aria-hidden', 'false').find('a, button').removeAttr('tabindex');
        this.shown = true;

        //ERB-52 International Shipping
        window.vbqUtils.priceDisplay.refresh();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    /*slide: function () {
        timer.clear();
        // show the item
        this.$content.slideDown('slow');
        // after a time out automatically close it
        timer.start(6000, this.close.bind(this));
    },*/
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        this.$link.attr('aria-expanded', 'false');
        // ECM23-60: minicart content shouldn't be navigated by tab if it's hidden
        this.$content.removeClass('active').attr('aria-hidden', 'true').find('a, button').attr('tabindex', '-1');
        this.shown = false;
    },
    addedToCartDialog: function (options, product) {
        // ECM23-60 - Mobile added-to-cart dialog displayed on desktop
        var isShowCartDialog = window.vbqUtils.isSR ? window.vbqUtils.breakpoint.is('mobileAndTabletAndDesktop') : window.vbqUtils.breakpoint.is('mobileAndTablet');

        if (isShowCartDialog) {
            var defaultOptions = {
                    autoOpen: false,
                    draggable: false,
                    modal: true,
                    resizable: false,
                    width: 'auto',
                    closeOnEscape: true,
                    closeText: window.Resources.CLOSE,
                    classes: {
                        'ui-dialog': 'minicart-mobile-alert-dialog' //used to force styles
                    },
                    focus: function (e) {
                        // EADA-74 - Focus on close button on opening
                        $(e.target).closest('.ui-dialog').find('.ui-dialog-titlebar-close').focus();
                    },
                    open: function (e) {
                        // EADA-40 - The modal container is not labeled as such: .dialog() case
                        window.vbqUtils.jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));

                        var $this = $(this);

                        if (product) {
                            if (product.name) {
                                $this.find('.js-mini-cart-alert-product-name').html(product.name);
                            }
                            if (product.size) {
                                $this.find('.js-mini-cart-alert-product-size-value').html(product.size);
                            }
                            if (product.color) {
                                $this.find('.js-mini-cart-alert-product-color').removeClass('hidden');
                                $this.find('.js-mini-cart-alert-product-color-value').html(product.color);
                            }
                        }
                    }
                };

            window.$cartAlertMobile = product && product.isCrossSell ? $('#js-miniCartAlert_crossSell').dialog($.extend(defaultOptions, options)) : $('#js-miniCartAlert').dialog($.extend(defaultOptions, options));

            window.$cartAlertMobile.dialog('open');

            window.vbqUtils.cache.$body.off('click', '#js-minicart-alert-close').on('click', '#js-minicart-alert-close', function () {
                if (window.$cartAlertMobile) {
                    window.$cartAlertMobile.dialog('close');
                }
            });
        }
    }
};

module.exports = minicart;
