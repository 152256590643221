'use strict';

var ajax = require('../../ajax'),
    minicart = require('../../minicart'),
    util = require('../../util'),
    amount = require('./amount'),
    specialday = require('./specialday'),
    _debounce = require('../../lib/lodash.debounce'),
    customerservice = require('../content/customerservice'),
    progress = require('../../progress'),
    cache = {},
    preventDoubleSubmit = false;

/**
 * Cache form elements
 */
function initCache() {
    cache.$document = $(document);
    cache.$formGiftcert = cache.$document.find('#js-GiftCertificateForm');
    cache.$formGiftcertSubmit = cache.$formGiftcert.find('.giftcert-purchase-submit');
    cache.$formCheckBalance = cache.$document.find('#js-check_balance_form');
    cache.$giftbalanceField = cache.$formCheckBalance.find('.js-gift_balance');
    cache.$removeGiftId = cache.$formCheckBalance.find('.js-gift_remove');
    cache.$checkBalanceButton = cache.$formCheckBalance.find('.js-check_balance');
    cache.$balanceSuccess = cache.$formCheckBalance.find('.js-balance_success');
    cache.$balanceError = cache.$formCheckBalance.find('.js-balance_error');
    cache.$shopNow = cache.$formCheckBalance.find('.js-shop_now');
}

var setAddToCartHandler = function (e) {
    e.preventDefault();

    var $form = $(this),
        $submit = $form.find('#AddToBasketButton'),
        validator = $form.validate(),
        // check for the form fields
        isValidForm = $form.valid(),
        // check the amount custom fields separately as the "amount" form field is hidden
        isAmountValid = amount.valid(),
        isValid = isValidForm && isAmountValid,
        $generalErrorWrapper = $form.find('.js-general_error');

    if (!isValid) {
        return;
    }

    if (preventDoubleSubmit) {
        return;
    }

    preventDoubleSubmit = true;
    progress.show($submit.attr('disabled', 'disabled'));

    // remove any previous error messages
    $generalErrorWrapper.html('').hide();

    var options = {
        url: util.ajaxUrl($form.attr('action')),
        method: 'POST',
        cache: false,
        data: $form.serialize()
    };
    $.ajax(options).done(function (response) {
        if (response.success) {
            var giftCertificate = response.result;

            ajax.load({
                url: Urls.minicartGC,
                data: {lineItemId: response.result.lineItemId},
                callback: function (response) {
                    try {
                        $form.find('input, textarea').val('');
                        amount.clear();
                        specialday.clear();
                    } catch (e) {}

                    // unblock submit button after form clear
                    preventDoubleSubmit = false;
                    progress.hide($submit.removeAttr('disabled'));

                    // if the form cleaning fails, at least update the minicart
                    minicart.show(response);

                    // render mobile GC add-to-cart alert
                    addedGiftCertAlert(giftCertificate);
                }
            });
        }
        else {
            preventDoubleSubmit = false;
            progress.hide($submit.removeAttr('disabled'));

            if (response && response.errors && response.errors.GeneralErrors) {
                $generalErrorWrapper.html(response.errors.GeneralErrors).show();
            }
            else {
                $form.find('span.error').hide();
                // security; do nothing if response is misssing
                if (!response || !response.errors || !response.errors.FormErrors) {
                    return;
                }

                validator.showErrors(response.errors.FormErrors);

                // amount input is hidden sow the error message is not visible if the field is invalid
                // show the error message on custom amount element
                if (response.errors.FormErrors.dwfrm_giftcert_purchase_amount) {
                    amount.showError(response.errors.FormErrors.dwfrm_giftcert_purchase_amount);
                }
            }
        }
    }).fail(function (xhr, textStatus) {
        // failed
        var errorMessage = textStatus === 'parsererror' ?
            Resources.BAD_RESPONSE :
            Resources.SERVER_CONNECTION_ERROR;

        $generalErrorWrapper.html(errorMessage).show();
        preventDoubleSubmit = false;
        progress.hide($submit.removeAttr('disabled'));
    });
};

/**
 * Check balance based on the provided gift certificate id
 */
function checkBalanceAction(e) {
    e.preventDefault();

    if (!cache.$formCheckBalance.valid()) {
        return;
    }

    if (preventDoubleSubmit) {
        return;
    }

    preventDoubleSubmit = true;
    progress.show(cache.$checkBalanceButton.attr('disabled', 'disabled'));

    var options = {
        url: util.ajaxUrl(cache.$formCheckBalance.attr('action')),
        method: 'POST',
        cache: false,
        data: cache.$formCheckBalance.serialize()
    };
    $.ajax(options).done(function (response) {
        // error means gift certificate has balance 0, is expired or not fount
        if (response.error) {
            // security, hide and remove any previous success messages
            cache.$balanceSuccess.html('').hide();

            cache.$formCheckBalance.addClass('has-backend-error');
            cache.$balanceError.html(response.message).show();
        }
        else if (response.value) {
            // security, hide and remove any previous error message
            cache.$formCheckBalance.removeClass('has-backend-error');
            cache.$balanceError.html('').hide();

            // hide submit button and show the balance result
            cache.$checkBalanceButton.hide();
            // show shop now link and message
            cache.$shopNow.removeClass('hidden');
            // show gift certificate balance value
            cache.$balanceSuccess
                .html(Resources.GIFT_CERTIFICATE_BALANCE.replace('{0}', response.value))
                .show();
        }
        else {
            cache.$formCheckBalance.addClass('has-backend-error');
            cache.$balanceError.html(Resources.GIFT_CERTIFICATE_ERROR).show();
        }
    }).fail(function (xhr, textStatus) {
        // failed
        var errorMessage = textStatus === 'parsererror' ?
            Resources.BAD_RESPONSE :
            Resources.SERVER_CONNECTION_ERROR;

        cache.$formCheckBalance.addClass('has-backend-error');
        cache.$balanceError.html(errorMessage).show();
    }).always(function () {
        preventDoubleSubmit = false;
        progress.hide(cache.$checkBalanceButton.removeAttr('disabled'));
    });
}

/**
 * Show "remove" link if the field has any gift id added
 * @param {Object} e - event object
 */
function giftBalanceFieldAction(e) {
    e.preventDefault();

    var $this = $(e.currentTarget);

    if ($this.val()) {
        cache.$removeGiftId.removeClass('hidden');
    }
    else {
        cache.$removeGiftId.addClass('hidden');
    }

    // security, hide and remove any previous error message
    cache.$formCheckBalance.removeClass('has-backend-error');
    cache.$balanceError.html('').hide();
}

/**
 * Remove gift certificate id
 * - show again the "check balance" button
 * - hide "shop now" link
 * @param {Object} e - event object
 */
function removeGiftIdAction(e) {
    e.preventDefault();

    // hide "remove" link
    $(e.currentTarget).addClass('hidden');
    // remove gift certificate id & clean field status
    cache.$giftbalanceField.val('').closest('.gl_input-wrapper').removeClass('filled');
    // show check balance button
    cache.$checkBalanceButton.show();
    // hide "shop now" link
    cache.$shopNow.addClass('hidden');
    // hide any error or success previous messages
    cache.$formCheckBalance.removeClass('has-backend-error');
    cache.$balanceError.html('').hide();
    cache.$balanceSuccess.html('').hide();
}

/**
 *  Init mobile GC add-to-cart alert
 */
function addedGiftCertAlert(giftcertificate) {
    if (window.vbqUtils.breakpoint.is('mobileAndTablet')) {
        cache.$giftCertAlertMobile = $('#js-giftcertAlert').dialog({
            autoOpen: false,
            draggable: false,
            modal: true,
            resizable: false,
            width: 'auto',
            closeOnEscape: true,
            closeText: window.Resources.CLOSE,
            classes: {
                'ui-dialog': 'minicart-mobile-alert-dialog' //used to force styles
            },
            open: function (e) {
                var $dialog = $(e.target).closest('.ui-dialog');

                // EADA-40 - The modal container is not labeled as such: .dialog() case
                window.vbqUtils.jqDialogOpenAdjust($dialog);

                // EADA-74 - Focus on close button on opening
                $dialog.find('.ui-dialog-titlebar-close').focus();

                var $this = $(this);

                if (giftcertificate) {
                    $this.find('.js-mini-cart-alert-giftcert-name').html('');
                    $this.find('.js-mini-cart-alert-giftcert-price').html('');
                    $this.find('.js-mini-cart-alert-giftcert-date').html('');
                    $this.find('.js-mini-cart-alert-giftcert-email-value').html('');
                    $this.find('.js-mini-cart-alert-giftcert-email').addClass('hidden');

                    if (giftcertificate.name) {
                        $this.find('.js-mini-cart-alert-giftcert-name').html(giftcertificate.name);
                    }
                    if (giftcertificate.price) {
                        $this.find('.js-mini-cart-alert-giftcert-price').html(giftcertificate.price);
                    }
                    if (giftcertificate.date) {
                        $this.find('.js-mini-cart-alert-giftcert-date').html(giftcertificate.date);
                    }
                    if (giftcertificate.recipientEmail) {
                        $this.find('.js-mini-cart-alert-giftcert-email').removeClass('hidden');
                        $this.find('.js-mini-cart-alert-giftcert-email-value').html(giftcertificate.recipientEmail);
                    }
                }
            },
            close: function () {
                // EADA-74 - Focus on modal opening element
                cache.$formGiftcertSubmit.focus();
            }
        });

        cache.$giftCertAlertMobile.dialog('open');
        window.vbqUtils.cache.$body.off('click', '#js-giftcert-alert-close').on('click', '#js-giftcert-alert-close', function () {
            if (cache.$giftCertAlertMobile) {
                cache.$giftCertAlertMobile.dialog('close');
            }
        });
    }
}

/**
 * Init events
 */
function initEvents() {
    // add mouseover validation
    window.vbqUtils.validationLogic.formValidation(cache.$formGiftcert);
    // add to cart gift certificate
    cache.$formGiftcert.on('submit', setAddToCartHandler);
    // check gift certificate balance
    cache.$formCheckBalance.on('submit', checkBalanceAction);
    // when gift certificate id is added for check balance
    cache.$giftbalanceField.on('keyup', _debounce(giftBalanceFieldAction, 100));
    // remove gift certificate id action from check balance form
    cache.$removeGiftId.on('click', removeGiftIdAction);
    // init custom amount functionality
    amount.init();
    // init specialday checkbox functionality
    specialday.init();
    // init FAQ accordeon js
    customerservice.init();
}

exports.init = function () {
    initCache();
    initEvents();
};
