/**
 *      (c) 2009-2014 Demandware Inc.
 *      Subject to standard usage terms and conditions
 *      For all details and documentation:
 *      https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var dialog = require('./dialog'),
    minicart = require('./minicart'),
    //page = require('./page'),
    //rating = require('./rating'),
    //ERF-75 removed blur functionality searchplaceholder = require('./searchplaceholder'),
    searchsuggest = require('./searchsuggest'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    accessibility = require('./accessibility'),
    validator = require('./validator'),
    tls = require('./tls'),
    // ECM22-20 - Remove jRespond and directly use window.matchMedia()
    breakpoint = require('./breakpoint.js'),
    // ERB-153 - Quickview support on any page with products list
    // Added the global require for product-tile.js
    productTile = require('./product-tile'),
    // ERB-173 Newsletter subscription
    newsletter = require('./newsletter'),
    //ERF-30 Header Integration - Included lodash and created cache
    _debounce = require('./lib/lodash.debounce'),
    progress = require('./progress'),
    backInStock = require('./pages/product/backInStock'),
    cache = {
        $window: $(window),
        $document: $(document),
        $html : $('html, body'),
        $body: $('body'),
        $footer: $('.js-footer'),
        $minicartLink: $('.mini-cart-link'),
        $globalWrapper: $('.gl_global-wrapper'),
        $headerBanners: $('.header-banner'),
        $headerTopBanner: $('.js-headerTopBanner'),
        $siteReviews: $('#site-reviews'),
        $mobileBreadcrumbs: $('.js-mobile-breadcrumbs .breadcrumb'),
        $stickyHeader: $('.js-header-sticky-panel'),
        $header: $('#js-header'),
        $headerSearchMobile: $('#js-headerSearchMobile'),
        $pageScrollUp: $('.js-pageScrollUp'),
        $main: $('#main'),
        $readMoreLinks: $('.js-readMoreButton'),
        $isHomepage: $('#js-homepage').length,
        $searchIcon: $('#js-mobileSearchIcon'),
        $links: $('.js-link'),
        tabCount: 0,
        $pdpMobileAddToCartStickyTrigger: $('#js-mobileAddToCartStickyTrigger')
    },
    //ERB-52 International Shipping
    priceDisplay = require('./pricedisplay'),
    // ERB-71 Gift box and gift message
    giftBoxMessages = require('./giftboxmessages'),
    // ERV-60 Account pages - header sticky change scroll position - Added initial header height global variable
    headerHeight = 0,
    //ERB-51 - SEO Tracking - Google Tag Manager - Google Tag Manager
    gtmUtils = require('./gtmUtils'),
    //ERV-485 - Checkout - Billing and Payment - Guest
    validationLogic = require('./validationLogic'),
    phoneCodes = require('./phone-codes'),
    cookiePrivacy = require('./cookieprivacy'),
    cookieHelper = require('./helpers/cookie'),
    carousel = require('./carousel'),
    password = require('./helpers/password'),
    phone = require('./helpers/phone'),
    dob = require('./helpers/dob'),
    registerchecks = require('./helpers/registerchecks'),
    byndervideo = require('./byndervideo'),
    recaptchaHelper = require('./helpers/recaptchaHelper'),
    vidoeforcarousel = require('./videoforcarousel'),
    //ECM23-81 - Refont style guide needs new blocks on Page Designer
    pdSwimwearstyles = require('./pagedesigner/swimwearstyles'),
    anchorbanner = require('./pagedesigner/anchorbanner.js'),
    beachSlider = require('./pagedesigner/beach_slider.js');

//require('./jquery-ext')();

/**
 * Scroll in the color list to selected color item
 */
function colorListSet($wrapper, isScrollbarReInit, forceFocus) {
    var $colorLists = $wrapper.find('.js-color-list');

    $colorLists.each(function () {
        var $colorList = $(this);

        // unset scroll before calculation
        $colorList.scrollLeft(0);

        if ($colorList[0] && $colorList[0].scrollWidth > $colorList[0].clientWidth) {
            var $selectedColor = $colorList.find('.selected');

            if (forceFocus) {
                $selectedColor = $colorList.find(':focus');
            }

            if ($selectedColor.length > 0) {
                var colorListWidth = $colorList.width(),
                    colorListWidthOffset = $colorList.offset(),
                    selectedColorWidth = $selectedColor.width(),
                    selectedColorOffset = $selectedColor.offset(),
                    scrollLeft = (selectedColorOffset.left - colorListWidthOffset.left) + selectedColorWidth - colorListWidth;

                if (scrollLeft > 0) {
                    $colorList.scrollLeft(scrollLeft);
                }
            }
        }

        // re-init custom scrollbar functionality
        if (isScrollbarReInit && $colorList.hasClass('js-custom-scrollbar')) {
            util.customScrollbarInit($colorList);
        }
    });
}

/**
 * Set mobile breadcrumbs scroll to last element
 */
function mobileBreadcrumbsSet(isScrollbarReInit) {
    if (cache.$mobileBreadcrumbs.length) {
        if (cache.$mobileBreadcrumbs[0].scrollWidth > cache.$mobileBreadcrumbs[0].clientWidth) {
            var $mobileLastBreadcrumb = cache.$mobileBreadcrumbs.find('.breadcrumb-element:last-child'),
                mobileBreadcrumbsWidth = cache.$mobileBreadcrumbs.width(),
                mobileLastBreadcrumbWidth = $mobileLastBreadcrumb.width(),
                mobileLastBreadcrumbOffset = $mobileLastBreadcrumb.offset();

            if (mobileLastBreadcrumbWidth >= mobileBreadcrumbsWidth) {
                cache.$mobileBreadcrumbs.scrollLeft(mobileLastBreadcrumbOffset.left - 20);
            } else {
                cache.$mobileBreadcrumbs.scrollLeft(mobileLastBreadcrumbOffset.left);
            }
        }

        // re-init custom scrollbar functionality
        if (isScrollbarReInit && cache.$mobileBreadcrumbs.hasClass('js-custom-scrollbar')) {
            util.customScrollbarInit(cache.$mobileBreadcrumbs);
        }
    }
}

function mobileMenuListeners($mobileMenuSections, $mobileMenuAllControls) {
    cache.$body.on('click keydown', '.js-mobileMenuItemTitle', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();
            var $this = $(this),
                $currentSection =  $this.next('.js-mobileMenuSection'),
                $currentSectionsControls = $mobileMenuSections.find('[data-key-access-level="' + $currentSection.attr('id') + '"]');

            $this.parent().addClass('open').parent('.js-mobileMenuSection').addClass('in');
            $currentSection.addClass('show').attr('aria-hidden', 'false');

            // manage mobile menu accessibility
            $mobileMenuAllControls.attr('tabindex', '-1');

            // EADA23-55 - SR #9: tabindex should be on custom cta only
            $currentSectionsControls.attr('tabindex', '0').filter('.js-native-cta').removeAttr('tabindex');

            // turn on circle focus in the defined wrapper
            // EADA21-12: focus on back btn on open submenu
            accessibility.trapFocusInit($currentSection, true);
        }
    });

    cache.$body.on('click keydown', '.js-mobileMenuBack', function (e) {
        // mouse click or enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $openMenuSection = $(this).parent().parent('.js-mobileMenuSection'),
                $openMenuTitle = $openMenuSection.siblings('.js-mobileMenuItemTitle');

            $openMenuSection.removeClass('show').parent().parent('.js-mobileMenuSection').removeClass('in');
            $openMenuSection.parent().removeClass('open');

            $openMenuSection.attr('aria-hidden', 'true');

            // manage mobile menu accessibility
            $mobileMenuAllControls.attr('tabindex', '-1')
                .filter('[data-key-access-level="' + $openMenuTitle.attr('data-key-access-level') + '"]').attr('tabindex', '0')
                // EADA23-55 - SR #9: tabindex should be on custom cta only
                .filter('.js-native-cta').removeAttr('tabindex');

            // EADA21-12: focus on target link on back to previous menu
            $openMenuTitle.focus();
        }
    });

    cache.$body.on('click', '.mobile-header-menu-item:not(.ui-state-active)', function (ev) {
        $(ev.target).closest('.mobile-header-menu-item').next().find('.ui-state-active').click();
    });

    cache.$body.on('click', '#js-mobileHelpMenuTrigger', function () {
        var $this = $(this);
        if ($this.hasClass('ui-state-active')) {
            $('body, html').animate({
                scrollTop: $this.offset().top
            });
        }
    });
}

function initializeEvents() {
    var isiOS = navigator.userAgent.match(/iPad|iPhone|iPod/i) !== null,
        controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    // Set mobile breadcrumbs scroll to last element
    mobileBreadcrumbsSet();
    cache.$window.resize(_debounce(function() {
        mobileBreadcrumbsSet(true);
    }, 50));

    //ERF-30 used cached value
    cache.$body
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        })
        // EW-75 - Zipcode checkout - Transform to uppercase
        .on('change input keyup', 'input[name$="_postal"]', function () {
            var $this = $(this),
                val = $this.val().toUpperCase();

            $this.val(val);
        });

    //initialize search suggestions
    if (window.vbqUtils.isSR) {
        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTabletAndDesktop'),
                enter: function () {
                    searchsuggest.destroy({
                        $container: $('#js-headerSearchMobile'),
                        cache: cache
                    });

                    searchsuggest.destroy({
                        $container: $('#js-headerSearchDesktop'),
                        cache: cache
                    });

                    searchsuggest.init({
                        $container: $('#js-headerSearchMobile'),
                        defaultValue: Resources.SIMPLE_SEARCH,
                        cache: cache
                    });
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktopLarge'),
                enter: function () {
                    searchsuggest.destroy({
                        $container: $('#js-headerSearchMobile'),
                        cache: cache
                    });

                    searchsuggest.destroy({
                        $container: $('#js-headerSearchDesktop'),
                        cache: cache
                    });

                    searchsuggest.init({
                        $container: $('#js-headerSearchDesktop'),
                        defaultValue: Resources.SIMPLE_SEARCH,
                        cache: cache
                    });
                }
            }
        ]);
    }
    else {
        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
                enter: function () {
                    searchsuggest.destroy({
                        $container: $('#js-headerSearchMobile'),
                        cache: cache
                    });

                    searchsuggest.destroy({
                        $container: $('#js-headerSearchDesktop'),
                        cache: cache
                    });

                    searchsuggest.init({
                        $container: $('#js-headerSearchMobile'),
                        defaultValue: Resources.SIMPLE_SEARCH,
                        cache: cache
                    });
                }
            },
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
                enter: function () {
                    searchsuggest.destroy({
                        $container: $('#js-headerSearchMobile'),
                        cache: cache
                    });

                    searchsuggest.destroy({
                        $container: $('#js-headerSearchDesktop'),
                        cache: cache
                    });

                    searchsuggest.init({
                        $container: $('#js-headerSearchDesktop'),
                        defaultValue: Resources.SIMPLE_SEARCH,
                        cache: cache
                    });
                }
            }
        ]);
    }

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    // EWR20-372 - Mobile / iPhone - Home Page - "UP" button
    cache.$pageScrollUp.click(function (e) {
        e.preventDefault();

        cache.$html.animate({
            scrollTop: 0
        }, {
            complete: function() {
                if (window.vbqUtils.isSR) {
                    if (window.vbqUtils.breakpoint.is('fromDesktopLarge')) {
                        // remove .page-scrolled to have 100% header panel visible to focus on element
                        cache.$body.removeClass('page-scrolled').find('#js-shipToLink')
                            // activate artificial outline & move focus
                            .addClass('focused').focus();
                    }
                    else {
                        $('#js-mobileLogo').addClass('focused').focus();
                    }
                }
                else {
                    if (window.vbqUtils.breakpoint.is('fromDesktop')) {
                        // remove .page-scrolled to have 100% header panel visible to focus on element
                        cache.$body.removeClass('page-scrolled').find('#js-shipToLink')
                            // activate artificial outline & move focus
                            .addClass('focused').focus();
                    }
                    else {
                        $('#js-mobileLogo').addClass('focused').focus();
                    }
                }
            }
        });
    });

    // remove inforced focused link display
    cache.$links.blur(function () {
        $(this).removeClass('focused');
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(this).data('href'),
            options: {
                dialogClass: 'privacy-policy-dialog js-faq js-faq-skip-scroll',
                open: function () {
                    // Init customerservice.js for the accordion custom code
                    pages.content.init();
                }
            }
        });
    });

    /*ERF-30 Header Integration
    * Solved Ipad issue with dropdown menus
    */
    if (isiOS) {
        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
                enter: function () {
                    // add extra classes to selectmenu plugin
                    fixIpadDropdowns();
                }
            }
        ]);
    }

    // EADA-34 - Global fix if other elements in modal catch Esc: Esc should close modal
    cache.$document.keyup(function(e) {
        // escape key
        if (e.keyCode === 27) {
            $('.ui-dialog-titlebar-close').trigger('click');
        }
    });

    // EADA21-8 - ADA #206: The escape key does not close the modal dialog on mobile
    cache.$document.keydown(function(e) {
        // escape key
        if (e.keyCode === 27) {
            if ($mobileMenu.hasClass('show')) {
                $mobileMenuClose.click();
            }
        }
    });

    // EWR20-14 - Navigation menu - desktop impacts and updates: pause on dropdown rendering
    var debounceDropdownShow,
        $dropdownList = $('.js-menuList'),
        $topMenuItems = $('.js-TopMenuItem'),
        $topMenuTriggers = $('.js-menuTrigger'),
        menuPanelIsVisible = false,
        menuSwitchTimeout = null;

    $topMenuTriggers
        .on('mouseenter', function () {
            var $currentElement = $(this),
                $currentMenuItem = $currentElement.find('.js-TopMenuItem'),
                $currentDropdownList = $currentElement.find('.js-menuList');

            clearTimeout(debounceDropdownShow);
            $topMenuItems.attr('aria-expanded', 'false');
            $dropdownList.removeClass('visible').attr('aria-hidden', 'true');

            if (!menuPanelIsVisible) {
                debounceDropdownShow = window.setTimeout(function () {
                    if ($currentElement.is(':hover')) {
                        $currentMenuItem.attr('aria-expanded', 'true');
                        $currentDropdownList.addClass('visible').attr('aria-hidden', 'false');
                        menuPanelIsVisible = true;

                        if (menuSwitchTimeout) { // security, should not happen with the configured delays (500 / 100)
                            window.clearTimeout(menuSwitchTimeout);
                        }
                    }
                }, 500);
            }
            else {
                $currentMenuItem.attr('aria-expanded', 'true');
                $currentDropdownList.addClass('visible').attr('aria-hidden', 'false');
                menuPanelIsVisible = true;
                if (menuSwitchTimeout) {
                    window.clearTimeout(menuSwitchTimeout);
                }
            }
        })
        .on('mouseleave', function () {
            clearTimeout(debounceDropdownShow);
            $topMenuItems.attr('aria-expanded', 'false');
            $dropdownList.removeClass('visible').attr('aria-hidden', 'true');
            menuSwitchTimeout = window.setTimeout(function () {
                menuPanelIsVisible = false;
            }, 100);
        });

    // EADA23-33 - ADA #189 - navigation menu cannot be triggered by screen readers.
    $topMenuItems.on('click keydown', function (e) {
        // keyboard control using enter/space key
        if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $currentTopMenuItem = $(this),
                $currentMenuList = $currentTopMenuItem.closest('.js-menuTrigger').find('.js-menuList'),
                isMenuVisible = $currentMenuList.hasClass('visible');

            $topMenuItems.not($currentTopMenuItem).attr('aria-expanded', 'false');
            $dropdownList.not($currentMenuList).removeClass('visible').attr('aria-hidden', 'true');
            $currentTopMenuItem.attr('aria-expanded', isMenuVisible ? 'false' : 'true');
            $currentMenuList.toggleClass('visible', !isMenuVisible).attr('aria-hidden', isMenuVisible ? 'true' : 'false');
        }
    });

    // EADA-22 - Help & Login menu in desktop header
    var $allDropdownMenu = $('.js-headerMenuControl'),
        $allDropdownMenuButtons = $('.js-headerMenuControlButton'),
        $allDropdownMenuArea = $('.js-headerMenuControlArea');

    $allDropdownMenu
        .on('mouseenter', function () {
            // hover logic on none touch devices
            if (!util.isTouchDevice()) {
                var $ariaParent = $(this),
                    $ariaButton = $ariaParent.find('.js-headerMenuControlButton'),
                    $ariaControl = $('#' + $ariaButton.attr('aria-controls'));

                // deactivate other header menu
                $allDropdownMenu.not($ariaParent).removeClass('hover');
                $allDropdownMenuButtons.not($ariaButton).attr('aria-expanded', 'false');
                $allDropdownMenuArea.not($ariaControl).attr('aria-hidden', 'true');

                // activate current header menu
                $ariaParent.addClass('hover');
                $ariaButton.attr('aria-expanded', 'true');
                $ariaControl.attr('aria-hidden', 'false');
            }
        })
        .on('mouseleave', function () {
            // unhover logic on none touch devices
            if (!util.isTouchDevice()) {
                var $ariaParent = $(this),
                    $ariaButton = $ariaParent.find('.js-headerMenuControlButton'),
                    $ariaControl = $('#' + $ariaButton.attr('aria-controls'));

                // deactivate current header menu
                $ariaParent.removeClass('hover');
                $ariaButton.attr('aria-expanded', 'false');
                $ariaControl.attr('aria-hidden', 'true');
            }
        });

    $('.js-headerMenuControlButton').on('keydown click', function (e) {
        // click logic on touch devices
        if ((e.type == 'click' && util.isTouchDevice()) ||
            // keyboard logic space/enter on all devices
            (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))
        ) {
            // prevent the default action to stop scrolling when space is pressed
            e.preventDefault();

            var $this = $(this);

            // skip keyboard logic if hovered target element on none touch devices
            if (!(e.type == 'keydown' && !util.isTouchDevice() && $this.is(':hover'))) {
                var $ariaParent = $this.parents('.js-headerMenuControl'),
                    $ariaControl = $('#' + $this.attr('aria-controls')),
                    isHeaderMenuActive = $this.attr('aria-expanded') == 'true' ? true : false;

                // deactivate all other menu on touch devices
                if (util.isTouchDevice()) {
                    $allDropdownMenu.not($ariaParent).removeClass('hover');
                    $allDropdownMenuButtons.not($this).attr('aria-expanded', 'false');
                    $allDropdownMenuArea.not($ariaControl).attr('aria-hidden', 'true');
                }
                // deactivate all other menu on none touch devices except hovered menu
                else {
                    $allDropdownMenu.not($ariaParent).each(function () {
                        var $dropdownMenu = $(this);

                        if (!$dropdownMenu.is(':hover')) {
                            $dropdownMenu.removeClass('hover');
                            $dropdownMenu.find('.js-headerMenuControlButton').attr('aria-expanded', 'false');
                            $dropdownMenu.find('.js-headerMenuControlArea').attr('aria-hidden', 'true');
                        }
                    });
                }

                $ariaParent.toggleClass('hover', !isHeaderMenuActive);
                $this.attr('aria-expanded', isHeaderMenuActive ? 'false' : 'true');
                $ariaControl.attr('aria-hidden', isHeaderMenuActive ? 'true' : 'false');
            }
        }
    });

    // EWR20-26 - Footer: footer wave adjustment
    var $footerWaveContainer = $('.js-footerWaveContainer'),
        $footerWave = $('.js-footerWave'),
        setWaveWidth = _debounce(function() {
            var cw = $footerWaveContainer.width(),
                waveWidth = 68 * Math.floor(cw / 68); // 68 is the wave pattern width

            $footerWave.width(waveWidth);
        }, 50);

    /*EWR20-27 - Footer - Mobile implementation: accordion init on mobile
    * ERF-30 Header Integration
    * Accordion trigger on mobile, managed minicart behaviour
    */
    var $footerAccordion = $('.js-footerAccordion'),
        $mobileMenu = $('.js-mobileMenu'),
        $mobileMenuItems = $('.js-mobileMenuItem'),
        $mobileMenuSections = $('.js-mobileMenuSection'),
        $mobileMenuOpen = $('.js-menu-toggle'),
        $mobileMenuClose = $('.js-mobileMenuClose'),
        $mobileMenuTopClose = $('.js-mobileMenuTopClose'),
        $mobileMenuAllControls = $mobileMenu.find('[role="button"], a, button'),
        $mobileMenuAllNativeControls = $mobileMenuAllControls.filter('.js-native-cta'),
        $mobileMenuAllCustomControls = $mobileMenuAllControls.filter(':not(.js-native-cta)');

    // set menu closed state: no accessibility
    $mobileMenuAllControls.attr('tabindex', '-1');

    // ERF-30 Header Integration - Used cached selector on wrapper
    // main menu toggle
    $mobileMenuOpen.on('click', function (e) {
        e.stopPropagation();

        cache.$body.toggleClass('menu-active');
        $mobileMenu.addClass('show');

        // ECM23-154: Enhance $mobileMenuOpen disability for open menu state
        $mobileMenuOpen.addClass('disabled');

        // init open menu initial state
        // EADA23-55 - SR #9: tabindex should be on custom cta only
        $mobileMenuAllNativeControls.filter('[data-key-access-level="mobile-menu-top-level"]').removeAttr('tabindex');
        $mobileMenuAllCustomControls.filter('[data-key-access-level="mobile-menu-top-level"]').attr('tabindex', '0');

        // focus on close menu button on menu open
        $mobileMenuTopClose.focus();

        // turn on circle focus in the defined wrapper
        accessibility.trapFocusInit($mobileMenu);

        // pause the messages banner autoplay, if the menu opens when the transition is active, the width computation bugs and is set to 0
        if (cache.$headerBanners.length) {
            cache.$headerBanners.each(function () {
                var $messageCarousel = $(this).find('.js-headerMessagesBanner');
                if ($messageCarousel.hasClass('slick-initialized')) {
                    $messageCarousel.slick('slickPause');
                }
            });
        }
    });

    $mobileMenuClose.on('click tap', function (e) {
        e.stopPropagation();

        cache.$body.removeClass('menu-active');
        $mobileMenuItems.removeClass('open');
        $mobileMenu.removeClass('show');
        $mobileMenuSections.removeClass('show in');

        // set menu closed state: no accessibility
        $mobileMenuAllControls.attr('tabindex', '-1');

        // focus on open menu button on menu close
        $mobileMenuOpen.focus();

        // resume the messages banner autoplay and force the next slide so the width is computed again
        if (cache.$headerBanners.length) {
            cache.$headerBanners.each(function () {
                var $messageCarousel = $(this).find('.js-headerMessagesBanner');
                if ($messageCarousel.hasClass('slick-initialized')) {
                    $messageCarousel.slick('slickNext').slick('slickPlay');
                }
            });
        }

        // ECM23-154: activate $mobileMenuOpen after few sec on menu close state
        setTimeout(function() {
            $mobileMenuOpen.removeClass('disabled');
        }, 500);
    });

    if (cache.$headerBanners.length) {
        cache.$headerBanners.each(function () {
            var $banner = $(this),
                $bannerContent = $banner.find('.js-headerMessagesBanner'),
                cookieName = $banner.attr('data-type') == 'top' ? 'htmr' : 'hsmr';

            if ($bannerContent.length) {
                // check if cookie is set and remove the banner
                if (cookieHelper.getCookie(cookieName)) {
                    $banner.remove();
                    return;
                }

                // show banner
                $banner.removeClass('hidden');

                // EW-105 - multiple header top banners
                // create slider when there are multiple messages
                var $bannerSlides = $banner.find('.banner');
                if ($bannerSlides.length > 1) {
                    $bannerContent.slick({
                        slidesToShow: 1,
                        dots: false,
                        arrows: false,
                        swipe: false,
                        autoplay: true,
                        pauseOnFocus: true,
                        pauseOnHover: true
                    });
                }
            }
        });

        // remove the banner and add the corresponding cookie when closing it
        $('.js-close-banner').on('click', function () {
            var $banner = $(this).parent(),
                cookieName = $banner.attr('data-type') == 'top' ? 'htmr' : 'hsmr';

            // EWR20-301 - post rebase problems: take into account the top header banner
            if ($banner.hasClass('js-headerTopBanner')) {
                cache.$body.removeClass('page-with-top-banner');
            }

            $banner.remove();
            cookieHelper.setCookie(cookieName, '1', 0);

            // the message(s) rows are part of the sticky header, update it
            determineFixedNavHeight();
            checkIfScrolled();
        });
    }

    // EWR20-301 - post rebase problems: take into account the top header banner
    if (cache.$headerTopBanner.find('.js-headerMessagesBanner').length) {
        cache.$body.addClass('page-with-top-banner');
    }

    // EWR20-22 - Sticky header
    window.vbqUtils.cache.$window.resize(_debounce(determineFixedNavHeight, 50));
    window.vbqUtils.cache.$window.scroll(_debounce(function () {
        checkIfScrolled();
        determineFixedNavHeight();
    }, 50));

    cache.$searchIcon.on('click', function () {
        // EWR20-65 - Mobile search input and icon implementation: ADA compliance
        var searchIconState = cache.$searchIcon.attr('aria-expanded');

        cache.$searchIcon.attr('aria-expanded', searchIconState == 'true' ? 'false' : 'true');

        if (cache.$headerSearchMobile.toggle().attr('aria-hidden', searchIconState).is(':visible')) {
            cache.$headerSearchMobile.find('.js-searchInput').focus();
        }

        cache.$header.toggleClass('search-open');
    });

    if (window.vbqUtils.isSR) {
        window.vbqUtils.breakpoint.set([
            {
                media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTabletAndDesktop'),
                enter: function () {
                    mobileMenuListeners($mobileMenuSections, $mobileMenuAllControls);
                }
            }
        ]);
    }

    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileAndTablet'),
            enter: function () {
                determineFixedNavHeight();
                checkIfScrolled();

                if (!window.vbqUtils.isSR) {
                    mobileMenuListeners($mobileMenuSections, $mobileMenuAllControls);
                }
                $footerAccordion.customAccordion();

                // EWR20-26 - Footer: footer wave adjustment
                setWaveWidth();
                cache.$window.resize(setWaveWidth);
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('fromDesktop'),
            enter: function () {
                // ERV-60 Account pages - header sticky change scroll position - Added initial header height global variable
                determineFixedNavHeight();
                checkIfScrolled();

                if ($footerAccordion.hasClass('accordion-initialized')) {
                    $footerAccordion.customAccordion('destroy');
                }

                cache.$body.removeClass('menu-active');
                cache.$body.on('click', '.mini-cart-link', function (ev) {
                    if (window.vbqUtils.breakpoint.is('fromDesktopLarge') && !$(this).hasClass('mini-cart-empty')) {
                        ev.preventDefault();
                    }
                });

                // EWR20-26 - Footer: footer wave adjustment
                $footerWave.width(1020); // fixed wave width: 15*68=1020
                cache.$window.off('resize', setWaveWidth);
            }
        }
    ]);

    /* ERV-651 - List page "View All" loader */
    window.vbqUtils.cache.$body.on('click', '.js-category-view-all', function () {
        window.vbqUtils.progressLoader(true, $(this).parent().find('.gl_ajax-loader'));
    });

    // ECM-937 - Avis Verifié Widget modification
    window.setTimeout(function () {
        cache.$siteReviews.each(function () {
            var $this = $(this),
                apiUrl = $this.data('site-reviews-api');

            if (apiUrl) {
                $.ajax({
                    url: apiUrl,
                    success: function (data) {
                        if (data && typeof data === 'string' && data.indexOf(';') > 0) {
                            // number of reviews at index 0
                            // review score at index 1
                            var reviewsData = data.split(';');

                            if (reviewsData.length === 2) {
                                var reviewScore = parseFloat(reviewsData[1]),
                                    reviewTotal = parseInt(reviewsData[0], 10),
                                    isScoreValid = (!isNaN(reviewScore) && reviewScore >= 0 && reviewScore <= 5),
                                    isTotalValid = (!isNaN(reviewTotal) && reviewTotal >= 0);

                                if (isScoreValid && isTotalValid) {
                                    var reviewScoreSplit = reviewScore.toFixed(1).split('.');

                                    $this.find('#js-reviewsScore').text(String.format(Resources.RATE_FORMAT, reviewScoreSplit[0], reviewScoreSplit[1]));
                                    $this.find('#js-reviewsTotal').text(reviewTotal);
                                    $this.find('#js-reviewsStars').rateYo({
                                        rating: reviewScore,
                                        starWidth: '12px',
                                        spacing: '2px'
                                    });
                                    $this.show();
                                }
                            }
                        }
                    }
                });
            }
        });
    }, 500);

    cache.$readMoreLinks.on('click keydown', showAllText);
}

/**
 * Hides the cropped text and shows the full text
 *
 * @param {Object} event -  A 'click' event
 */
function showAllText(e) {
    // mouse click or enter/space key
    if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
        // prevent the default action to stop scrolling when space is pressed
        e.preventDefault();

        $(e.target).closest('.js-readMoreBlock').addClass('show-full-text');
    }
}

/**ERF-30 Header Integration
 * checkIfScrolled - checks if page is scrolled,
 * if so it applies class .page-scrolled to body
 */
function checkIfScrolled() {
    if (window.vbqUtils.isStickyNav) {
        // not sticky header on mobile PDP
        var isMobile = window.vbqUtils.breakpoint.is('mobileAndTablet');

        if (cache.$window.scrollTop() > 0 && !(window.pageContext.ns == 'product' && isMobile)) {
            if (!cache.$body.hasClass('page-scrolled')) {
                cache.$body.addClass('page-scrolled');
                cache.$main.css({
                    'marginTop': headerHeight
                });

                /* SR-211 - remove the search bar on mobile
                * keep specific search section display on VBQ HP only
                */
                if (!window.vbqUtils.isSR && cache.$isHomepage) {
                    cache.$headerSearchMobile.attr('aria-hidden', 'true').hide();
                    cache.$searchIcon.attr('aria-expanded', 'false').css('display', 'flex');
                    cache.$header.removeClass('search-open');
                }

                window.vbqUtils.cache.$body.trigger('header:sticky');
            }
        }
        else {
            if (cache.$body.hasClass('page-scrolled')) {
                cache.$body.removeClass('page-scrolled');
                cache.$main.css({
                    'marginTop': 0
                });

                /* SR-211 - remove the search bar on mobile
                * keep specific search section display on VBQ HP only
                */
                if (!window.vbqUtils.isSR && cache.$isHomepage) {
                    cache.$headerSearchMobile.removeAttr('aria-hidden').show();
                    cache.$searchIcon.hide();
                    cache.$header.addClass('search-open');
                }
            }
        }
    }
}

/**ERF-30 Header Integration
 * fixIpadDropdowns is making iPad menu dropdowns close properly
 * when user taps outside the dropdown
 */
function fixIpadDropdowns() {
    var isDropdownVisible = false,
        $latestTarget = false;

    cache.$body.on('touchstart', function (ev) {//make active inputs blur when tap outside
        var isInputFocused = $(':focus').is('input'),
            $focusedEl = $(':focus');

        if (isInputFocused) {
            $focusedEl.blur();
        }
    });

    cache.$body.on('click touchend', function (ev) {
        var $target = $(ev.target);

        if (!$target.closest('.gl_dropdown-trigger').length) {
            cache.$body.focus();

            isDropdownVisible = false;
        } else {
            ev.stopPropagation();

            if ($target.hasClass('gl_dropdown-link') || $target.closest('.gl_dropdown-link').length) {
                if (!$latestTarget || $latestTarget != $target[0]) {
                    isDropdownVisible = false;

                    if (!isDropdownVisible) {
                        ev.preventDefault();
                    }

                    setTimeout(function () {
                        isDropdownVisible = true;
                        $latestTarget = $target[0];
                    }, 200);
                }
            }
        }
    });
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }

    // load js specific styles
    util.limitCharacters();

    // Initialize the jQuery UI elements
    initializeJQueryUiPlugins();

    // Add space for UFD sticky slider
    if ($('#js-ultraFastDeliverySlider').length > 0) {
        cache.$footer.addClass('ufd-slider-footer-place');
    }
}

/**
 * @private
 * @function
 * @description Initializes jQuery UI plugins
 */
function initializeJQueryUiPlugins() {
    // ERF-54 Login page - Init UI style for .gl_radio, .gl_checkbox - ancestry for isml modules & self for pure HTML
    var $customStyleInputs = $('.gl_radio, .gl_checkbox');
    $customStyleInputs.filter('input[type=radio], input[type=checkbox]').add($customStyleInputs.find('input[type=radio], input[type=checkbox]')).not('#js-addToAddressBook, #js-useAsBillingAddress').checkboxradio();

    // ERF-58  Product detail page - Force load lazyload images to construct proper DOM layout
    window.vbqUtils.cache.$window.on('load', function (e) {
        if (window.$ === undefined || window.$ === null) {
            window.$ = window.jQuery;
        }

        $('.lazyload.forced').each(function (index, el) {
            window.lazySizes.loader.unveil(el);
        });
    });
}

// EW-46 Revised DESKTOP forms
function initFloatingLabels ($forms) {
    var updateStatus = function () {
        var $this = $(this);
        $this.closest('.gl_input-wrapper').toggleClass('filled', $this.val() !== '');
    };

    $forms.each(function () {
        var $form = $(this);
        $form.find('.gl_floating-label').siblings('.input-text').off('input blur change', updateStatus)
            // focus case is managed by CSS
            .on('input blur change', updateStatus) // input and change events are needed to cover autofill cases (chrome, lastpass, ...)
            .each(updateStatus);
    });
}

// EW-169 - Header revision
var determineFixedNavHeight = function () {
    headerHeight = cache.$header.outerHeight(true); // margin used on mobile with the search form

    if (cache.$headerBanners.is(':visible')) { // this will take both message(s) rows
        headerHeight += cache.$headerBanners.outerHeight();
    }
};

// EADA-40 - The modal container should be labeled as such
var jqDialogOpenAdjust = function ($dialog) {
    $dialog = $dialog || $();

    var $dialogTitle = $dialog.find('.ui-dialog-title') || $(),
        $customDialogTitle = $dialog.find('.js-dialog-title') || $(),
        customDialogTitleID = $customDialogTitle.attr('id') || $customDialogTitle.uniqueId().attr('id');

    if ($dialog.length) {
        $dialog.attr('aria-modal', 'true')
        // EADA21-77 - ADA #8 - Popup US 15% - The close button is not labeled for screen reader users
        .find('.ui-dialog-titlebar-close').attr('aria-label', window.Resources.CLOSE)
        // EADA-17 - ADA for close button on jquery dialog
        .find('.ui-button-icon').attr('aria-hidden', 'true');
    }

    // EADA23-25 - Labelledby must link on the heading: dialog custom title ID
    if ($customDialogTitle.length) {
        $dialog.attr('aria-labelledby', customDialogTitleID);
    }

    // EADA23-24 - Put aria-hidden="true" on .ui-dialog-title
    if ($dialogTitle.length) {
        $dialogTitle.attr('aria-hidden', 'true');
    }
};

var pages = {
    account: require('./pages/account'),
    outlet: require('./pages/outlet'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    //compare: require('./pages/compare'),
    product: require('./pages/product'),
    //registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator'),
    content: require('./pages/content'),
    // ERB-52 International Shipping
    contextchooser: require('./pages/contextchooser'),
    // EGC-10 Add ajax loader on GC add-to-cart action
    giftcert: require('./pages/giftcert'),
    // ERB-189 - Gifting pages
    gifting: require('./pages/gifting'),
    // ERV-43 My Account - change password issue
    orderconfirmation: require('./pages/orderconfirmation/index.js'),
    //ERF-88 Size Charts page
    sizecharts: require('./pages/sizecharts'),
    //ENF-60 Style guide page
    styleguide: require('./pages/styleguide'),
    //WeChatPayQR is finaly not proposed (business decision)
    //qrcodepending: require('./pages/qrcodepending'),
    //PR-17 50th anniversary Repair Service pages
    repairserviceshow: require('./pages/repairserviceshow'),
    repairserviceregister: require('./pages/repairserviceregister'),
    repairserviceproductslist: require('./pages/repairserviceproductslist'),
    repairserviceproductsoverview: require('./pages/repairserviceproductsoverview'),
    repairrequest: require('./pages/repairrequest'),
    personalinformation: require('./pages/personalinformation'),
    y2customerform: require('./pages/y2customerform'),
    y2customerform2: require('./pages/y2customerform2'),
    y2registrationconfirmation: require('./pages/y2registrationconfirmation')
};

function getCookie(name) {
    var nameEQ = name + '=',
        ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

// ENF-58 - CRO - new popup for registration
function initRegistrationDialog() {
    var $registrationDialog = $('#js-registrationDialog'),
        isNewsletterPage = cache.$main.hasClass('newsletter-page');

    if ($registrationDialog.length
        && !cache.$globalWrapper.hasClass('pt_checkout')
        && !cache.$globalWrapper.hasClass('pt_account')
        && !cache.$globalWrapper.hasClass('pt_cart')
        && !cache.$globalWrapper.hasClass('pt_contest')
        && !cache.$globalWrapper.hasClass('pt_order-confirmation')
        // always render form on newsletter page & ignore registrationDialog cookie rp
        && (getCookie('rp') === null || isNewsletterPage)
        && $('#js-contextChooserModal').length === 0
    ) {
        var cDate;

        var $rpSuccessButton = $registrationDialog.find('#js-registrationDialogSuccessButton'),
            $rpSuccessLink = $registrationDialog.find('#js-registrationDialogSuccessLink');

        if (isNewsletterPage) {
            // manage success display
            $rpSuccessButton.remove();
        }
        else {
            // manage success display
            $rpSuccessLink.remove();

            $registrationDialog.dialog({
                autoOpen: false,
                draggable: false,
                modal: true,
                resizable: false,
                width: 'auto',
                closeOnEscape: true, // EADA-34 - All modal can be closing on escape key: .dialog() case
                closeText: window.Resources.CLOSE,
                title: window.Resources.CREATE_ACCOUNT,
                classes: {
                    'ui-dialog': 'registrationDialog' //used to force styles
                },
                open: function (e) {
                    var $dialog = $(e.target).closest('.ui-dialog');

                    // EADA-40 - The modal container is not labeled as such: .dialog() case
                    jqDialogOpenAdjust($dialog);

                    // EADA-74 - Focus on close button on opening
                    $dialog.find('.ui-dialog-titlebar-close').focus();

                    initializeJQueryUiPlugins();
                },
                close: function () {
                    // EADA21-74 - ADA - #12 Popup US 15% -The alert message is not read to screen reader users
                    // $registrationDialog is destroyed so role="alert" elements have been removed
                    $registrationDialog.remove();

                    if (getCookie('rp') === null) { // do not override the success cookie
                        cDate = new Date();
                        cDate.setDate(cDate.getDate() + 15); // 15 days
                        document.cookie = 'rp=1; expires=' + cDate.toGMTString() + '; path=/';
                    }
                }
            });
        }

        var $registrationDialogForm = $('#js-registrationDialogForm');

        // init phone codes if the field exists
        var $countryCodeField = $registrationDialogForm.find('[name="newsletter_country_code"]'),
            $phoneField = $registrationDialog.find('[name="newsletter_customer_phone"]');

        if ($countryCodeField && $countryCodeField.length > 0 && $phoneField && $phoneField.length > 0) {
            // security, if the country code field is empty use the session country code value
            if (!$countryCodeField.val()) {
                $countryCodeField.val(window.vbq.localizationData.countryCode);
            }
            window.vbqUtils.phoneCodes.init($countryCodeField, $phoneField);
        }

        $registrationDialogForm.validate(); //required before adding rule
        $registrationDialogForm.find('#registrationDialog-email').rules('add', {email: true});

        //ERV-485 - Checkout - Billing and Payment - Guest - apply form validation logic improvements
        window.vbqUtils.validationLogic.formValidation($registrationDialogForm);

        //ERB-51 - SEO Tracking - Google Tag Manager - push GTM data on submit
        $registrationDialogForm.on('submit', function (e) {
            e.preventDefault();
            if ($registrationDialogForm.valid()) {
                var params = {
                    email: $registrationDialogForm.find('#registrationDialog-email').val(),
                    firstname: $registrationDialogForm.find('#registrationDialog-firstname').val(),
                    lastname: $registrationDialogForm.find('#registrationDialog-lastname').val(),
                    origin: isNewsletterPage ? 'US-SubscriptionPage' : 'registrationpopup'
                };

                // send phone value only if the field was found.
                if ($phoneField && $phoneField.length > 0) {
                    params.phone = window.intlTelInputGlobals.getInstance($phoneField[0]).getNumber();
                }

                progress.show($registrationDialogForm.find('.registration-dialog-button').addClass('disabled'));

                $.ajax({
                    url: $registrationDialogForm.attr('action'),
                    method: 'POST', // match guard limitations
                    data: params
                })
                .done(function (data) {
                    progress.hide();
                    $registrationDialogForm.find('.registration-dialog-button').removeClass('disabled');

                    if (data.type == 'success' || data.type == 'consents') {
                        var $registrationDialogSuccess= $('#js-registrationDialogSuccessWrapper').removeClass('hidden');

                        // EADA21-76 ADA - Popup US 15% - This content is only visible to screen reader users
                        $('#js-registrationDialogFormWrapper').addClass('gl_invisible'); // keep space
                        $('.registrationDialog').find('.ui-dialog-title').text($registrationDialogSuccess.find('.registration-dialog-title').text());

                        // popup images
                        if (!isNewsletterPage || window.vbqUtils.isSR) {
                            $('.registration-dialog-main-image').addClass('gl_invisible'); // keep space
                            $('.registration-dialog-success-image').removeClass('hidden');
                        }

                        // EADA21-74 - ADA - #12 Popup US 15% -The alert message is not read to screen reader users
                        $registrationDialogSuccess.find('.registration-dialog-alert').attr('role', 'alert');

                        cDate = new Date();
                        cDate.setFullYear(cDate.getFullYear() + 50); // 50 years
                        document.cookie = 'rp=1; expires=' + cDate.toGMTString() + '; path=/';
                    }
                    else if (data.error) {
                        if (data.type == 'already') {
                            var $registrationDialogError=$('#js-registrationDialogErrorWrapper').removeClass('hidden');

                            // EADA21-76 ADA - Popup US 15% - This content is only visible to screen reader users
                            $('#js-registrationDialogFormWrapper').addClass('gl_invisible'); // keep space
                            $('.registrationDialog').find('.ui-dialog-title').text($registrationDialogError.find('.registration-dialog-title').text());

                            // popup images
                            if (!isNewsletterPage || window.vbqUtils.isSR) {
                                $('.registration-dialog-main-image').addClass('gl_invisible'); // keep space
                                $('.registration-dialog-error-image').removeClass('hidden');
                            }

                            // EADA21-74 - ADA - #12 Popup US 15% -The alert message is not read to screen reader users
                            $registrationDialogError.find('.registration-dialog-alert').attr('role', 'alert');

                            cDate = new Date();
                            cDate.setFullYear(cDate.getFullYear() + 50); // 50 years
                            document.cookie = 'rp=1; expires=' + cDate.toGMTString() + '; path=/';
                        }
                        else {
                            // javascript validation should have catch the error before the submit, closing the dialog to avoid any more problems
                            // popup
                            if (!isNewsletterPage) {
                                $registrationDialog.dialog('close');
                            }
                        }
                    }
                })
                .fail(function () {
                    // popup
                    if (!isNewsletterPage) {
                        $registrationDialog.dialog('close');
                    }
                });
            }
        });

        $('#js-registrationDialogErrorContinueButton, #js-registrationDialogSuccessButton').click(function (e) {
            e.preventDefault();

            if (isNewsletterPage) {
                // re-init registration form initial display
                $('#js-registrationDialogSuccessWrapper').addClass('hidden').find('.registration-dialog-alert').removeAttr('role');
                $('#js-registrationDialogErrorWrapper').addClass('hidden').find('.registration-dialog-alert').removeAttr('role');
                $('#js-registrationDialogFormWrapper').removeClass('gl_invisible');
            }
            else {
                $registrationDialog.dialog('close');
            }
        });

        if (!isNewsletterPage && $('.contextchooser-wrapper').length === 0) {
            // open registration dialog as the 2nd dialog after cookie dialog
            if ($('#js-cookieDialog').length > 0) {
                window.vbqUtils.cache.$document.on('closeCookieDialog', function() {
                    window.setTimeout(function () {
                        $registrationDialog.dialog('open');
                    }, 50); // give jQuery UI some time to clean up the previous dialog before opening the new one
                });
            }
            else {
                window.setTimeout(function () {
                    $registrationDialog.dialog('open');
                }, 5000);
            }
        }
    }
    else {
        $registrationDialog.remove();
    }
}

var app = {
    init: function () {
        //ERF-56 Account pages - An object to pass functions/variables to other modules.
        window.vbqUtils = window.vbqUtils || {};
        window.vbqUtils = $.extend({}, window.vbqUtils, {
            cache: cache,
            initializeJQueryUiPlugins: initializeJQueryUiPlugins,
            breakpoint: breakpoint,
            // ERB-71 Gift box and gift message
            giftBoxMessages: giftBoxMessages,
            isDevice: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent),
            priceDisplay: priceDisplay,
            wishlist: pages.wishlist,
            _debounce: _debounce,
            dialog: dialog,
            progress: progress,
            //ERB-51 - SEO Tracking - Google Tag Manager
            gtmUtils: gtmUtils,
            //ERV-485 - Checkout - Billing and Payment - Guest
            validationLogic: validationLogic,
            // EW-46 - Revised DESKTOP forms
            initFloatingLabels: initFloatingLabels,
            // EWR20-37 - Product carousel
            carousel: carousel,
            // EW-108 Checkout - Phone
            phoneCodes: phoneCodes,
            // YTWSC-4 Y2 tablet with site catalog
            productTile: productTile,
            // EW-169 - Header revision
            determineFixedNavHeight: determineFixedNavHeight,
            getCookie: cookieHelper.getCookie,
            setCookie: cookieHelper.setCookie,
            progressLoader: util.progressLoader,
            // Scroll in the color list to selected color item
            colorListSet: colorListSet,
            // EADA-87 - Loading indicators - add screen reader alert
            loaderTemplate: cache.$document.find('#js-svg-loader-template').html(),
            // EADA-40 - The modal container should be labeled as such
            jqDialogOpenAdjust: jqDialogOpenAdjust,
            // Store SR sites variable in window
            isSR: window.vbq.siteId.indexOf('SR') != -1,
            isStickyNav: !($('.js-no-sticky-nav').length),
            // ECM23-139 - Upgrade our recaptcha version to recaptcha V3
            recaptchaHelper: recaptchaHelper
        });

        // run in scope with window.vbqUtils definition
        require('./captcha')();

        // VSIS-3 - Virtual Shopping - SFCC 3 steps: include datepicker on VS pages only with vslib.min.js: see pt_virtualshopping.isml
        // Note: require() works in app.js ONLY (because of browserify, see gulpfile.js for details)
        if ($('.pt_virtualshopping').length) {
            window.vbqUtils.datepicker = require('./datepicker');
        }

        // ECM21-336 - Init validator before usage
        validator.init();

        // ENF-58 - CRO - new popup for registration
        initRegistrationDialog();

        cookiePrivacy();

        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        //ERF-75 Search Results integration initializeDom();
        initializeDom();
        initializeEvents();

        // init custom password validation only when the specific class is found
        if ($('.customPasswordValidation').length) {
            password.initCustomValidation();
        }

        // init phone codes and dob for registration form
        if ($('#RegistrationForm').length) {
            phone.initPhoneCodes();
            dob.init();
            registerchecks.init();
        }

        // set global ajax timeout
        $.ajaxSetup({timeout: window.Constants.AJAX_TIMEOUT});

        // init specific global components
        //countries.init(); countries = require('./countries'),
        tooltip.init();
        minicart.init();
        //rating.init();
        //ERF-75 removed blur functionality searchplaceholder.init();
        // ERB-173 Newsletter subscription
        newsletter.init();
        // ERB-153 - Quickview support on any page with products list
        // Added the global init for productTile
        productTile.init();
        // Init any found carousel on the page
        carousel.init();
        // Init any "dynamic" bynder video on the page
        byndervideo.init();
        // Init vidoeforcarousel if found on the page
        vidoeforcarousel.init();
        // init custom scrollbar functionality
        $('.js-custom-scrollbar').each(function () {
            util.customScrollbarInit($(this));
        });
        // Init fake radio group according to the ADA Compliance
        accessibility.fakeRadioGroupInit();
        // Init tab arrows keys accessibility to the ADA Compliance
        accessibility.ajaxTabAccessibilityInit();
        // execute page specific initializations
        var ns = window.pageContext.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

        // EOS-1 - Outlet sites
        if (window.vbq.siteId && window.vbq.siteId.indexOf('OUTLET') == -1) {
            //ERB-214 Storelocator - footer input
            var $storeLocatorForm = $('#storelocatorFooterForm');

            $storeLocatorForm.on('submit', function (e) {
                e.preventDefault();
                var value = $('#storelocatorFooterSearch').val();

                if (value !== null && value !== '') {
                    window.location = $(this).attr('action') + '#' + value;
                }
            });
        }

        if (window.vbq
            && window.vbq.localizationData
            && window.vbq.localizationData.countryCode == 'not_supported'
        ) {
            pages.contextchooser.showModal();
        }

        var event = document.createEvent('Event');

        window.vbqUtils.cache.$window.trigger('mainJsLoaded');
        event.initEvent('mainJsLoaded', true, true);//Need vanilla js event declaration as well since vanilla js can not listen to jquery custom events
        window.dispatchEvent(event);

        // force activation of wishlist icons for any page with product tiles on it (Landing Pages, blog, ...)
        if (window.pageContext.type != 'search' && window.pageContext.type != 'product') {
            window.vbqUtils.wishlist.updateWishlistIcons();
        }

        var $recentProductsView = $('.search-result-view-more'),
            $recentProductsContainer = $('#js-recentproductsList');

        if ($recentProductsView.length && $recentProductsContainer.length) {
            // Recently viewed products load more
            $recentProductsView.on('click', function (e) {
                e.preventDefault();
                var $this = $(this),
                    begin = parseInt($this.data('current'), 10),
                    end = begin + 4;

                $.ajax({
                    type: 'GET',
                    url: util.appendParamsToUrl($this.data('href') , {'format': 'ajax', 'begin': begin, 'end': end}),
                    success: function (data) {
                        $recentProductsContainer.append(data);
                        $this.data('current', end);

                        if ($recentProductsContainer.find('#endOfVisitedItems').length) {
                            $this.remove();
                        }

                        productTile.init();

                        //ERB-51 - SEO Tracking - Google Tag Manager - update product impressions
                        window.vbqUtils.gtmUtils.updateProductImpressions();
                        window.vbqUtils.priceDisplay.refresh();
                    }
                });
            });
        }

        initFloatingLabels($('form'));

        $('.toggle-password').on('click keydown', function(e) {
            // mouse click or enter/space key
            if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
                // prevent the default action to stop scrolling when space is pressed
                e.preventDefault();

                var $this = $(this),
                    $input = $($this.attr('toggle')),
                    isPasswordState = $input.attr('type') == 'password';

                $this.attr('aria-pressed', isPasswordState ? 'true' : 'false').toggleClass('active');
                $input.attr('type', isPasswordState ? 'text' : 'password');
            }
        });

        // EW-130 - CRO - PdP Cross Sell
        // use a shared function as the listener needs to be updated after each PDP ajax update (color change)
        window.vbqUtils.crossSellButtonAction = function (e) {
            // mouse click or enter/space key
            if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
                // prevent the default action to stop scrolling when space is pressed
                e.preventDefault();

                var $crossSellTrigger = $(this),
                    url = $crossSellTrigger.attr('data-href'),
                    $crossSellLoader = $crossSellTrigger.hasClass('cross-sell-bikini-tile') ? $crossSellTrigger.closest('#js-crossSellBlock').find('.js-crosssell-progress-loader') : $crossSellTrigger.find('.js-crosssell-progress-loader');

                window.vbqUtils.progressLoader(true, $crossSellLoader);
                url = util.appendParamToURL(url, 'source', 'cross-sell');
                url = util.appendParamToURL(url, 'custom', true);
                url = util.appendParamToURL(url, 'format', 'ajax');

                // Get the cross-sell markup, then open the cross-sell panel
                var crossSellDialog = dialog.open({
                    target: $('<div/>').attr('id', 'cross-sellDialog').appendTo(document.body),
                    url: url,
                    bodyClass: 'cross-sell-active',
                    customCloseSelector: '.js-cross-sell-close',
                    options: {
                        width: 'auto',
                        closeOnEscape: true,
                        title: window.Resources.CROSS_SELL_STYLE,
                        closeText: window.Resources.CLOSE,
                        dialogClass : 'cross-sell-dialog',
                        show: {effect: 'slide', direction: 'right'},
                        position: {
                            my: 'center',
                            at: 'center',
                            of: window,
                            collision: 'fit'
                        },
                        open: function () {
                            var $wrapper = $(this).find('.js-pdp-main'),
                                $this = $(this);

                            //show price on cross-sell product modal
                            window.vbqUtils.priceDisplay.refresh();

                            $this.find('.cross-sell-dialog').addClass('opened');

                            window.vbqUtils.cache.$document.on('viewportchange', function (e, viewportName, viewportValue) {
                                if (viewportValue <= 2) {
                                    crossSellDialog.close();
                                }
                            });

                            window.vbqUtils.setupProductPrimaryImage($wrapper);
                            backInStock.init($wrapper);
                            window.vbqUtils.progressLoader(false, $crossSellLoader);
                        }
                    }
                });
            }
        };

        $('.js-crossSellLink').on('click keydown', window.vbqUtils.crossSellButtonAction);

        // ENF-83 - Footer - WeChat QR Code
        var $weChatDialog = $('#js-weChatDialog'),
            $weChatOpen = $('.js-weChatIcon');

        $weChatOpen.on('click', function (e) {
            e.preventDefault();

            window.vbqUtils.cache.$body.css('overflow', 'hidden');

            $weChatDialog.dialog({
                title: window.Resources.WECHAT_FOLLOWUS,
                autoOpen: true,
                draggable: false,
                modal: true,
                resizable: false,
                width: '800',
                closeText: window.Resources.CLOSE,
                closeOnEscape: true, // EADA-34 - All modal can be closing on escape key: .dialog() case
                classes: {
                    'ui-dialog': 'weChatDialog' //used to force styles
                },
                /*
                show: {
                    effect: 'slide',
                    direction: 'right'
                },
                position: {
                    my: 'right top',
                    at: 'right top',
                    of: window,
                    collision: 'fit'
                },
                */
                open: function (e) {
                    // EADA-40 - The modal container is not labeled as such: .dialog() case
                    jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));

                    $('.weChatDialog').addClass('opened');
                },
                close: function () {
                    // activate body scroll
                    window.vbqUtils.cache.$body.css('overflow', '');

                    // EADA-33 - Closing a modal should return the user to the element that opened it
                    $weChatOpen.focus();
                }
            });

            $('#js-close-wechat').on('click', function () {
                $weChatDialog.dialog('close');
            });
        });

        // EADA-21 - Social media links: attach enter on open weChatDialog button
        $weChatOpen.on('keypress', function (e) {
            if (e.keyCode === 13) {
                $weChatOpen.trigger('click');
            }
        });

        // EW-80 - Home Page - Main bloc Video
        // add missing attributes on the Bynder video player
        var $videos = $('.js-bynderVideo');
        if ($videos.length) {
            var updateVideoPlayers = function () {
                var $players = $videos.find('video');
                if ($players.length) {
                    $players.each(function () {
                        var $player = $(this);
                        $player.attr('controlsList', 'nodownload').attr('oncontextmenu', 'return false;');

                        if ($player.attr('autoplay') == 'autoplay' && !!navigator.platform.match(/iPhone|iPod|iPad/)) {
                                // see https://webkit.org/blog/6784/new-video-policies-for-ios/
                                // see https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
                                // on iOS <video> needs to be muted to autoplay
                                // playsinline avoid the direct fullscreen mode on autoplay
                            $player.attr('muted', 'muted').attr('playsinline', 'playsinline');
                        }
                    });
                }

                if ($players.length !== $videos.length) {
                    window.setTimeout(function () {
                        updateVideoPlayers();
                    }, 250);
                }
            };

            updateVideoPlayers();
        }

        // ERB-52 - International Shipping
        priceDisplay.init();

        // ERB-51 - SEO Tracking - Google Tag Manager
        gtmUtils.init();

        /* ECM-1026 - Remove the session timeout logic
        // ECM-804 - ApplePay different currencies errors
        // Set a 30 minutes timeout, then reset the localization data through AJAX
        var sessionTimer;
        function setSessionTimer() {
            sessionTimer = setTimeout(function () {
                window.alert(window.Resources.SESSION_TIMEOUT);

                var url = window.Urls.updateSessionAjax,
                    countryCode = window.vbq && window.vbq.localizationData && window.vbq.localizationData.countryCode,
                    currencyCode = window.vbq && window.vbq.localizationData && window.vbq.localizationData.currencyCode;

                if (countryCode && currencyCode) {
                    $.ajax({
                        url: url,
                        data: 'country=' + countryCode + '&currency=' + currencyCode + '&format=ajax',
                        type: 'POST',
                        timeout: 3000,
                        success: function (data) {
                            window.location = window.Urls.home;
                        },
                        error: function () {
                            window.location = window.Urls.home;
                        }
                    });
                }
                else {
                    window.location = window.Urls.home;
                }
            }, 20 * 60 * 1000); // ECM-955 - Order creation error - Lowered the timeout to 20 minutes
        }
        setSessionTimer();

        $(document).ajaxSuccess(function (event, xhr, settings) {
            if (settings.url.indexOf('vilebrequin.com') > -1) {
                clearTimeout(sessionTimer);
                setSessionTimer();
            }
        });*/

        var $crisisModal = $('#js-crisisModal');
        if ($crisisModal.length && getCookie('cm') === null) {
            $crisisModal.dialog({
                autoOpen: true,
                draggable: false,
                modal: true,
                resizable: false,
                width: 'auto',
                closeOnEscape: true,
                closeText: window.Resources.CLOSE,
                classes: {
                    'ui-dialog': 'crisisDialog' //used to force styles
                },
                open: function (e) {
                    var cDate = new Date();
                    cDate.setDate(cDate.getDate() + 15); // 15 days
                    document.cookie = 'cm=1; expires=' + cDate.toGMTString() + '; path=/';

                    // EADA-40 - The modal container is not labeled as such: .dialog() case
                    window.vbqUtils.jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));
                },
                close: function () {
                    $crisisModal.remove();
                }
            });
        }

        // OUFD20-2 - Omnichannel Ultra Fast Delivery
        // listeners for ultraFastDelivery products search filtering logic
        $('.js-ultraFastDeliveryFilteringForm').on('submit', function (e) {
            e.preventDefault();

            var $form = $(this),
                $postalCodeInput = $form.find('.js-ultraFastDeliveryPostalCode'),
                postalCodeAriaDescribedBy = $postalCodeInput.attr('aria-describedby') || '',
                postalCode = $postalCodeInput.val();

            if (postalCode === '' || postalCode === null) { // security, should not happen as the input is required
                // TODO set the postal code field in error
                return false;
            }

            progress.show($form);
            var $error = $('#js-ultraFastDeliveryError').addClass('hidden'),
                $choices = $('#js-ultraFastDeliveryChoices').addClass('hidden'),
                $notPossible = $('#js-ufdNotPossible').addClass('hidden'),
                $possible = $('#js-ufdPossible').addClass('hidden'),
                $resultOK = $('.js-checkUltraFastDeliveryResultOK').addClass('hidden'),
                $resultKO = $('.js-checkUltraFastDeliveryResultKO').addClass('hidden'),
                $ufdFooter = $('#js-ufdLandingFooter').removeClass('ufd-landingpage-footer-mobile-sticky-place');

            $postalCodeInput.attr('aria-describedby', $.trim(postalCodeAriaDescribedBy.replace($error.attr('id'), '')));
            $.ajax({
                url: window.Urls.setUltraFastDeliveryParameter,
                method: 'POST', // match guard limitations
                data: {
                    format: 'ajax',
                    postalCode: postalCode
                }
            })
            .done(function (data) {
                postalCodeAriaDescribedBy = $postalCodeInput.attr('aria-describedby') || '';

                if (data.success) {
                    if (data.ufd) {
                        // display choices and button
                        $choices.removeClass('hidden');
                        $('#ufdwith').prop('checked', true).checkboxradio('refresh');
                        $('#ufdwithout').prop('checked', false).checkboxradio('refresh');
                        $possible.removeClass('hidden');
                        $resultOK.removeClass('hidden');
                    }
                    else {
                        // display error message
                        $error.html(data.reason).removeClass('hidden');
                        $postalCodeInput.attr('aria-describedby', postalCodeAriaDescribedBy + ' ' + $error.attr('id'));
                        $notPossible.attr('href', data.continueURL).removeClass('hidden');
                        $resultKO.removeClass('hidden');

                        // EADA23-5 ADA Focus on the input field if error message is displayed
                        $postalCodeInput.focus();
                    }
                }
                else {
                    // display error message
                    $error.html(data.reason).removeClass('hidden');
                    $postalCodeInput.attr('aria-describedby', postalCodeAriaDescribedBy + ' ' + $error.attr('id'));
                    $notPossible.attr('href', data.continueURL).removeClass('hidden');
                    $resultKO.removeClass('hidden');

                    // EADA23-5 ADA Focus on the input field if error message is displayed
                    $postalCodeInput.focus();
                }
            })
            .fail(function (data) {
                // display error message
                $error.html(window.Resources.GLOBAL_AJAX_ERROR).removeClass('hidden');
                $postalCodeInput.attr('aria-describedby', postalCodeAriaDescribedBy + ' ' + $error.attr('id'));
                $notPossible.attr('href', data.continueURL).removeClass('hidden');
                $resultKO.removeClass('hidden');

                // EADA23-5 ADA Focus on the input field if error message is displayed
                $postalCodeInput.focus();
            })
            .always(function () {
                progress.hide();
                $ufdFooter.addClass('ufd-landingpage-footer-mobile-sticky-place');
            });
        });

        $('#js-ufdPossibleSubmit').on('click', function (e) {
            e.preventDefault();

            progress.show($(this));

            $.ajax({
                url: window.Urls.toggleUltraFastDeliveryFiltering,
                method: 'POST', // match guard limitations
                data: {
                    format: 'ajax',
                    ufdChoice: $('#ufdwith').prop('checked') ? 'with' : 'without'
                }
            })
            .done(function (data) {
                if (data.success) {
                    window.location = data.continueURL ? data.continueURL : window.Urls.home;
                }
                else {
                    // display error message
                    $('#js-ultraFastDeliveryError').html(window.Resources.GLOBAL_AJAX_ERROR).removeClass('hidden');
                    progress.hide();
                }
            })
            .fail(function () {
                // display error message
                $('#js-ultraFastDeliveryError').html(window.Resources.GLOBAL_AJAX_ERROR).removeClass('hidden');
                progress.hide();
            });
        });

        $('#js-ufdReset').on('click', function (e) {
            e.preventDefault();

            progress.show($(this));

            $.ajax({
                url: window.Urls.resetUltraFastDeliveryParameters,
                method: 'POST', // match guard limitations
                data: {
                    format: 'ajax'
                }
            })
            .done(function (data) {
                if (data.success) {
                    window.location = data.continueURL ? data.continueURL : window.Urls.home;
                }
                else {
                    // display error message
                    $('#js-ultraFastDeliveryError').html(window.Resources.GLOBAL_AJAX_ERROR).removeClass('hidden');
                    progress.hide();
                }
            })
            .fail(function () {
                // display error message
                $('#js-ultraFastDeliveryError').html(window.Resources.GLOBAL_AJAX_ERROR).removeClass('hidden');
                progress.hide();
            });
        });

        $('#js-ultraFastDeliverySliderClose').on('click', function () {
            $('#js-ultraFastDeliverySlider').addClass('hidden');

            // remove space for UFD sticky slider
            cache.$footer.removeClass('ufd-slider-footer-place');
        });

        // list toggle on Ultra Fast Delivery storepage
        var $ufdStorePageList = $('.js-postalCodeList');

        $('.js-togglePostalCodeList').on('click', function () {
            var isHidden = $ufdStorePageList.hasClass('hidden');

            $(this).attr('aria-expanded', (isHidden ? 'true' : 'false'));
            $ufdStorePageList.toggleClass('hidden').attr('aria-hidden', (isHidden ? 'false' : 'true'));
        });

        // ECM23-81 - Refont style guide needs new blocks on Page Designer
        pdSwimwearstyles.init();
        // VOW-26 - New revision Hospitality LP
        anchorbanner.init();
        beachSlider.init();
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
jQuery(document).ready(function ($) { // pass $ as paramter to scope it and avoid problems with GTM scripts overriding the $ variable (rakuten)
    app.init();

    // ENRP-3 - Replacement of Power-Reviews by Avis-Vérifiés
    // Changes necessary to ensure there aren't any jQuery conflicts, and to only load the JS on PDPs
    if (window.reviewUrl) {
        $.ajax({
            url: window.reviewUrl,
            dataType: 'script',
            cache: true,
            timeout: 2000
        })
        .always(function () {
            window.$ = window.jQuery;
        });
    }
});
